import { useCallback, MouseEvent, ChangeEvent, useEffect, useRef } from 'react';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

export interface UsePaginationResult {
  page: number;
  perPage: number;
  handlePageChange: (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => void;
  handleRowsPerPageChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetPagination: () => void;
}

const PageParam = withDefault(NumberParam, 0);
const PerPageParam = withDefault(NumberParam, 25);

export default function usePagination(resetDependencies: any[] = []): UsePaginationResult {
  const hasMounted = useRef(false);

  const [page, setPage] = useQueryParam('page', PageParam);
  const [perPage, setPerPage] = useQueryParam('perPage', PerPageParam);

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
      setPage(nextPage);
    },
    [setPage]
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPerPage(Number(event.target.value));
      setPage(0);
    },
    [setPerPage, setPage]
  );

  const resetPagination = useCallback(() => {
    setPage(0);
  }, [setPage]);

  useEffect(() => {
    if (hasMounted.current) {
      resetPagination();
    }

    hasMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDependencies);

  return {
    page,
    perPage,
    handlePageChange,
    handleRowsPerPageChange,
    resetPagination,
  };
}
