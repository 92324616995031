import { FC } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TableContainer } from '@mui/material';
import { useGetSkeletonArray } from 'utils';
import TemplateItemSkeleton from './TemplateItemSkeleton';

interface TemplateListSkeletonProps {
  size?: number;
}

const TemplateListSkeleton: FC<TemplateListSkeletonProps> = ({ size = 5 }) => {
  const screens = useGetSkeletonArray(size);

  return (
    <TableContainer>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={{ width: '250px' }}>
              <TableSortLabel>ID</TableSortLabel>
            </TableCell>
            <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>
              <TableSortLabel>Payment type</TableSortLabel>
            </TableCell>
            <TableCell component="th">Parameters</TableCell>
            <TableCell component="th" sx={{ width: '150px' }}>
              <TableSortLabel>Start version</TableSortLabel>
            </TableCell>
            <TableCell component="th" sx={{ width: '200px' }}>
              <TableSortLabel>Updated</TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: '70px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {screens.map(({ id }) => (
            <TemplateItemSkeleton key={id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TemplateListSkeleton;
