import { apiClient } from 'api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryConfig } from 'lib/react-query';
import { Project } from '../types';
import getProjectFromDTO from './getProjectFromDTO';
import { ProjectDTO } from './types';
import queryKeys from './queryKeys';

interface GetProjectOptions {
  projectId: number;
}

export const getProject = async ({ projectId }: GetProjectOptions, signal?: AbortSignal): Promise<Project> => {
  const res = await apiClient.get<ProjectDTO>(`/admin/projects/${projectId}`, { signal });
  return getProjectFromDTO(res.data);
};

type QueryFnType = typeof getProject;

type UseProjectOptions = {
  projectId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useGetProject = ({ projectId, config }: UseProjectOptions) =>
  useSuspenseQuery({
    gcTime: 0,
    ...config,
    queryKey: queryKeys.detail(projectId),
    queryFn: ({ signal }) => getProject({ projectId }, signal),
  });
