import { apiClient } from 'api';
import { QueryConfig } from 'lib/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { Flow } from '../types';

export type GetFlowsOptions = {
  projectId: number;
};

export const getFlows = async ({ projectId }: GetFlowsOptions, signal?: AbortSignal) => {
  const res = await apiClient.get<Flow[]>(`/admin/projects/${projectId}/flows`, {
    signal,
  });
  return res.data;
};

export type UseGetFlowsQuery = {
  config?: QueryConfig<typeof getFlows>;
  projectId: number;
};

export const useGetFlowsQuery = ({ config, projectId }: UseGetFlowsQuery) =>
  useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFlows({ projectId }, signal),
    queryKey: queryKeys(projectId).list(),
  });
