import { Paper } from '@mui/material';
import { FC, Suspense, lazy } from 'react';
import { PageLayout, TableListErrorBoundary } from 'components';
import { UsersListSkeleton } from 'features/users';

const UsersList = lazy(() => import('features/users/components/UsersList'));

const FeatureSetListRoute: FC = () => (
  <PageLayout outlet name="Users managment">
    <Paper>
      <TableListErrorBoundary>
        <Suspense fallback={<UsersListSkeleton />}>
          <UsersList />
        </Suspense>
      </TableListErrorBoundary>
    </Paper>
  </PageLayout>
);

export default FeatureSetListRoute;
