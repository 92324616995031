import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Alert,
  AlertTitle,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { useGetSkeletonArray } from 'utils';
import { FC } from 'react';
import { SidePageLayout } from 'components';

const UserPageSkeleton: FC = () => {
  const projects = useGetSkeletonArray(10);

  return (
    <SidePageLayout
      fullHeight
      name={<Skeleton width={150} />}
      footer={
        <>
          <Skeleton variant="rectangular" width={80} height={35} />
          <Skeleton variant="rectangular" width={80} height={35} />
        </>
      }
    >
      <Alert severity="info">
        <AlertTitle>Pay attention</AlertTitle>
        These rules will be applied <b>only</b> when the user has the Edit role within cognito groups.
      </Alert>

      <Box mt={2}>
        <Box>
          <Typography variant="overline" sx={{ px: 2 }}>
            Projects
          </Typography>
          <Paper sx={{ px: 2, mt: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
                    Name
                  </TableCell>
                  <TableCell variant="head" component="th" sx={{ backgroundColor: 'white', width: 100 }}>
                    Read
                  </TableCell>
                  <TableCell variant="head" component="th" sx={{ backgroundColor: 'white', width: 100 }}>
                    Write
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((prj) => (
                  <TableRow key={prj.id}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Skeleton width={70} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={70} height={38} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={70} height={38} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Box>
    </SidePageLayout>
  );
};

export default UserPageSkeleton;
