import { useProject } from 'features/project';
import { UseGetVersionsQuery, useGetVersionsQuery } from '../api';

export type UseGetVersions = Pick<UseGetVersionsQuery, 'config' | 'params'>;

export const useGetVersions = ({ config, params }: UseGetVersions) => {
  const project = useProject();
  const projectId = project.id;

  return useGetVersionsQuery({
    params,
    config,
    projectId,
  });
};
