import { FC } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Chip,
  Skeleton,
} from '@mui/material';
import { SidePageLayout } from 'components';

const ExperimentPageSkeleton: FC = () => (
  <SidePageLayout
    name={<Skeleton width={70} />}
    headerSidebar={<Skeleton variant="rectangular" sx={{ ml: 'auto' }} height={48} width={190} />}
  >
    <Box>
      <Typography variant="overline" sx={{ px: 2 }}>
        <Skeleton />
      </Typography>
      <Paper sx={{ px: 2, mt: 1 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell>
                <Chip size="small" label={<Skeleton />} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Typography variant="overline" sx={{ px: 2 }}>
        <Skeleton />
      </Typography>
      <Paper sx={{ px: 2, mt: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                <Skeleton />
              </TableCell>
              <TableCell variant="head">
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  </SidePageLayout>
);

export default ExperimentPageSkeleton;
