import { useProject } from 'features/project';
import { useArchiveTemplateMutation, UseArchiveTemplateMutation } from '../api';

export type UseArchiveTemplate = {
  config?: UseArchiveTemplateMutation['config'];
};

export const useArchiveTemplate = ({ config }: UseArchiveTemplate = {}) => {
  const project = useProject();
  const projectId = project.id;

  return useArchiveTemplateMutation({ config, projectId });
};
