import {
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  TextField,
  AutocompleteInputChangeReason,
  AutocompleteChangeReason,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Project } from 'features/project';
import { ProjectContext } from 'features/project/context';

import ProjectIcon from './ProjectIcon';
import { useGetProjects } from '../api/getProjects';

const ProjectSelect: FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const { data: projects } = useGetProjects();
  const { project, selectedProject, setProject } = useContext(ProjectContext);

  useEffect(() => setShowPlaceholder(false), [project]);

  const handleOnInputChange = useCallback(
    (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
      if (reason === 'input') {
        setShowPlaceholder(true);
      }
    },
    []
  );

  const handleOnChange = useCallback(
    (event: React.SyntheticEvent, value: Project | null, reason: AutocompleteChangeReason) => {
      if (value) {
        setProject(value);
      }
      if (reason === 'clear') {
        setShowPlaceholder(true);
      }
    },
    [setProject]
  );

  const isProjectUpdating = project.id !== selectedProject?.id;

  const handleOnBlur = useCallback(() => setShowPlaceholder(false), []);

  if (!projects) return null;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 300 }}>
      <Autocomplete
        fullWidth
        sx={{ ml: 1 }}
        id="tags-standard"
        options={projects as Project[]}
        getOptionLabel={(option) => option.name}
        inputValue={inputValue}
        value={selectedProject}
        disabled={isProjectUpdating}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onInputChange={handleOnInputChange}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        groupBy={(option) => option.group_label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: showPlaceholder ? (
                <ProjectIcon sx={{ mr: 1 }} />
              ) : (
                <ProjectIcon sx={{ mr: 1 }} proxy={selectedProject?.proxy} platform={selectedProject?.platform} />
              ),
              endAdornment: isProjectUpdating ? (
                <InputAdornment position="end" sx={{ mr: '-5px' }}>
                  <CircularProgress size={20} color="inherit" />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
              sx: {
                '::before, ::after': { display: 'none' },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ProjectIcon proxy={option.proxy} platform={option.platform} />
            <ListItemText sx={{ ml: 1 }} primaryTypographyProps={{ noWrap: true }}>
              {option.name}
            </ListItemText>
          </ListItem>
        )}
      />
    </Box>
  );
};

export default ProjectSelect;
