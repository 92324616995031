import {
  Box,
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';
import { PageLayout } from 'components';
import { FC } from 'react';

const TemplateFormPage: FC = () => (
  <PageLayout title={null} name={<Skeleton width={160} />}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={10} md={4}>
        <Paper sx={{ p: 3 }}>
          <Skeleton variant="rounded" height={56} />
          <Skeleton sx={{ mt: 3 }} variant="rounded" height={56} />
          <Skeleton sx={{ mt: 3 }} variant="rounded" height={56} />
        </Paper>

        <Paper sx={{ p: 3, mt: 2.5 }}>
          <Typography variant="overline" sx={{ px: 2 }}>
            <Skeleton />
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="head">
                  <Skeleton />
                </TableCell>
                <TableCell variant="head">
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        <Skeleton variant="rectangular" height={84.5} />
        <Skeleton variant="rectangular" height={500} sx={{ mt: 1 }} />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Skeleton variant="rounded" height={36.5} />
        </Box>
      </Grid>
    </Grid>
  </PageLayout>
);

export default TemplateFormPage;
