/* eslint-disable react/destructuring-assignment */
import { FC } from 'react';
import { LinearProgress, LinearProgressProps, Theme } from '@mui/material';

const GrayscaleProgress: FC<LinearProgressProps> = (props) => (
  <LinearProgress
    {...props}
    sx={[
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      (theme: Theme) => ({
        backgroundColor: theme.palette.grey[300],
        '& .MuiLinearProgress-bar': {
          backgroundColor: theme.palette.grey[100],
        },
      }),
    ]}
  />
);

export default GrayscaleProgress;
