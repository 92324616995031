import { useLocation } from 'react-router';

export default function useTabMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    if (pathname.endsWith(`/${pattern}`) || pathname.includes(`/${pattern}/`)) {
      return pattern;
    }
  }

  return null;
}
