import { LinearProgress } from '@mui/material';
import { useGetExperiments } from 'features/experiments';
import { FC, Suspense, lazy } from 'react';
import { withSuspense } from 'utils';

const FlowPage = lazy(() => import('features/flows/components/FlowPage'));

// TODO use it to prefetch experiments
// Now it commented until standardize flow skeleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExperimentsPrefetcher = withSuspense(() => {
  useGetExperiments();
  return null;
});

const FlowRoute: FC = () => (
  <>
    {/* <ExperimentsPrefetcher /> */}

    <Suspense fallback={<LinearProgress sx={{ mx: -4 }} />}>
      <FlowPage />
    </Suspense>
  </>
);

export default FlowRoute;
