import { FC, Suspense, lazy } from 'react';
import { GrayscaleProgress } from 'components';
import { useTypedParams } from 'utils';

const ReviewConfigPage = lazy(() => import('features/configs/components/ReviewConfigPage'));

const ReviewConfigRoute: FC = () => {
  const { configId } = useTypedParams({
    configId: Number,
  });

  return (
    <Suspense fallback={<GrayscaleProgress />}>
      <ReviewConfigPage configId={configId} />
    </Suspense>
  );
};

export default ReviewConfigRoute;
