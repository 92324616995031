import { LinearProgress } from '@mui/material';
import { FC, Suspense, lazy } from 'react';

const FlowsPage = lazy(() => import('features/flows/components/FlowsPage'));

const FlowsRoute: FC = () => (
  <Suspense fallback={<LinearProgress />}>
    <FlowsPage />
  </Suspense>
);
export default FlowsRoute;
