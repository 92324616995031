import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  OpenInNewOutlined as OpenInNewOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Stack,
  Button,
  Paper,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Chip,
} from '@mui/material';
import { format } from 'date-fns';
import { useModal } from 'hooks';
import { extractJSONParams, getUserAvatarNameByEmail, getUserNameByEmail } from 'utils';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SidePageLayout } from 'components';
import TemplateArchiveDialog from './TemplateArchiveDialog';
import TemplatePaymentTypeChip from './TemplatePaymentTypeChip';
import { useGetTemplate } from '../hooks';
import ModificationInfo from './ModificationInfo';

interface TemplatePageProps {}

const TemplatePage: FC<TemplatePageProps> = () => {
  const { isOpen, open, close } = useModal();

  const { data } = useGetTemplate();
  const template = data!;

  const parent = useMemo(() => template?.parent, [template]);

  const templatePageHeader = useMemo(
    () => (
      <Stack ml="auto">
        <Typography variant="caption" component="div">
          Created: {format(template.created_at, 'dd.MM.yyyy')}
        </Typography>
        <Typography variant="caption" component="div">
          Updated: {format(template.updated_at, 'dd.MM.yyyy')}
        </Typography>
      </Stack>
    ),
    [template.created_at, template.updated_at]
  );

  const templatePageFooter = useMemo(
    () =>
      template.end_version?.id ? (
        <Button
          component={Link}
          to="edit"
          variant="contained"
          startIcon={<OpenInNewOutlinedIcon />}
          color="success"
          sx={{ marginLeft: 'auto' }}
        >
          Restore
        </Button>
      ) : (
        <>
          <Button variant="outlined" startIcon={<ArchiveOutlinedIcon />} color="error" onClick={open}>
            Archive
          </Button>
          <Button component={Link} to="edit" variant="contained" startIcon={<EditIcon />} color="primary">
            Edit
          </Button>
        </>
      ),
    [template.end_version, open]
  );

  return (
    <>
      <TemplateArchiveDialog template={template} isOpen={isOpen} onClose={close} />

      <SidePageLayout
        //
        fullHeight
        name={template.identifier}
        headerSidebar={templatePageHeader}
        footer={templatePageFooter}
      >
        <Box>
          <Typography variant="overline" sx={{ px: 2 }}>
            Info
          </Typography>
          <Paper sx={{ px: 2, mt: 1 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell variant="head" scope="row">
                    Type
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={template.type} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" scope="row">
                    Payment type
                  </TableCell>
                  <TableCell>
                    {template.payment_type ? (
                      <TemplatePaymentTypeChip type={template.payment_type} />
                    ) : (
                      <Chip size="small" label="None" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" scope="row">
                    Start version
                  </TableCell>
                  <TableCell>{template.start_version.name}</TableCell>
                </TableRow>
                {template.end_version && (
                  <TableRow>
                    <TableCell variant="head" scope="row">
                      End version
                    </TableCell>
                    <TableCell>{template.end_version.name}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Box>
        {parent?.schema && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="overline" sx={{ px: 2 }}>
              Inherited parameters
            </Typography>
            <Paper sx={{ px: 2, mt: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell variant="head">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extractJSONParams(parent.schema).map(([name, type, options]) => (
                    <TableRow>
                      <TableCell>{(options?.prefix ?? '') + name}</TableCell>
                      <TableCell>
                        {type}
                        {options?.modification && <ModificationInfo modification={options.modification} />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        )}
        {template.schema ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="overline" sx={{ px: 2 }}>
              Own parameters
            </Typography>
            <Paper sx={{ px: 2, mt: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell variant="head">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extractJSONParams(template.schema).map(([name, type, options]) => (
                    <TableRow>
                      <TableCell>{(options?.prefix ?? '') + name}</TableCell>
                      <TableCell>
                        {type}
                        {options?.modification && <ModificationInfo modification={options.modification} />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        ) : null}
        <Box sx={{ mt: 3 }}>
          <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0, background: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }}>
              <Typography variant="overline" sx={{ px: 2 }}>
                History
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Paper sx={{ px: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Date</TableCell>
                      <TableCell variant="head">Type</TableCell>
                      <TableCell variant="head">Editor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {template.events!.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>{format(new Date(event.created_at), 'dd.MM.yyyy HH:mm')}</TableCell>
                        <TableCell>
                          <Chip label={event.type} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          {event.user ? (
                            <>
                              <Avatar sx={{ width: 24, height: 24, display: 'inline-flex', mr: 1 }}>
                                <Typography variant="body2">{getUserAvatarNameByEmail(event.user.email)}</Typography>
                              </Avatar>
                              <span>{getUserNameByEmail(event.user.email)}</span>
                            </>
                          ) : (
                            <>
                              <Avatar sx={{ width: 24, height: 24, display: 'inline-flex', mr: 1 }}>
                                <Typography variant="body2">T</Typography>
                              </Avatar>
                              <span>system</span>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Box>
      </SidePageLayout>
    </>
  );
};

export default TemplatePage;
