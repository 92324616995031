import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton } from '@mui/material';
import { useGetSkeletonArray } from 'utils';
import { FC } from 'react';

const FeaturesListSkeleton: FC = () => {
  const features = useGetSkeletonArray(10);

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={{ backgroundColor: 'white', width: 50 }}>
              ID
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeaturesListSkeleton;
