import { APIListResponse, ListResponse } from '../types';

const getAPIListResponse = <T>(res: APIListResponse<T>): ListResponse<T> => {
  const [items, meta] = res;

  return {
    total: meta.total_items,
    items,
  };
};

export default getAPIListResponse;
