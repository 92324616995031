import { useTypedParams } from 'utils';
import { DrawerPage } from 'components';
import { FC, Suspense, lazy } from 'react';
import { UserPageSkeleton } from 'features/users';

const UserPage = lazy(() => import('features/users/components/UserPage'));

const FeatureSetListRoute: FC = () => {
  const { userId } = useTypedParams({ userId: Number });

  return (
    <DrawerPage>
      <Suspense fallback={<UserPageSkeleton />}>
        <UserPage userId={userId} />
      </Suspense>
    </DrawerPage>
  );
};

export default FeatureSetListRoute;
