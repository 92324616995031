import { createContext, useContext } from 'react';

export type AuthStateType = 'unauthenticated' | 'authenticated' | 'inProgress';

export type TokenPayload = {
  email: string;
  'cognito:groups': Array<string>;
};

export interface UserData {
  name: string;
  email: string;
}

export interface AuthStateValue {
  user: UserData | null;
  state: AuthStateType;
  roles: Roles;
}

export interface Roles {
  isAdmin: boolean;
  isEditor: boolean;
}

export const AuthContext = createContext<AuthStateValue>({} as AuthStateValue);

export function useAuth() {
  return useContext(AuthContext);
}
