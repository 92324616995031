import { Amplify } from 'aws-amplify';
import config from 'config';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolClientId: config.COGNITO_APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: config.COGNITO_DOMAIN,
          scopes: ['email', 'openid'],
          redirectSignIn: [config.COGNITO_REDIRECT_SIGN_IN],
          redirectSignOut: [config.COGNITO_REDIRECT_SIGN_OUT],
          responseType: 'code',
        },
      },
    },
  },
});
