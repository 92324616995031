import {
  Box,
  Paper,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useProject } from 'features/project';
import { FC, Suspense, lazy } from 'react';
import { Search, Sync as SyncIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { usePagination, useSearch, useSorting } from 'hooks';
import { Product, ProductType, useSyncProducts, ProductsListSkeleton, ProductStatus } from 'features/products';
import { useProjectFeatureSets } from 'features/feature-sets';
import { PageLayout, TableListErrorBoundary } from 'components';
import { Platform } from 'types';

const ProductsList = lazy(() => import('features/products/components/ProductsList'));

const ProductsListRoute: FC = () => {
  const project = useProject();
  const { search, searchFieldValue, handleSearchFieldChange } = useSearch();

  const [type, setType] = useQueryParam('type', withDefault(StringParam, ''));
  const [featureSet, setFeatureSet] = useQueryParam('feature-set', withDefault(StringParam, ''));
  const [status, setStatus] = useQueryParam('status', withDefault(StringParam, ''));

  const sorting = useSorting<Product>('status');

  const isWebProject = project.platform === Platform.Web;

  const pagination = usePagination([search, sorting.field, sorting.order, type, featureSet, status]);

  const { enqueueSnackbar } = useSnackbar();

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleSetChange = (event: SelectChangeEvent) => {
    setFeatureSet(event.target.value);
  };

  const { mutate: refresh, isPending } = useSyncProducts({
    config: {
      onSuccess: () => {
        enqueueSnackbar('Products synchronization has been scheduled!', { variant: 'info' });
      },
    },
  });

  const { data: featureSets, isPending: featureSetsisPending } = useProjectFeatureSets({
    params: { field: 'name', order: 'asc' },
  });

  const isSyncing =
    isPending || (project.products_sync !== null && ['in_progress', 'scheduled'].includes(project.products_sync));

  return (
    <PageLayout
      name="Products"
      headerActions={
        !isWebProject ? (
          <LoadingButton variant="contained" loading={isSyncing} onClick={() => refresh()}>
            <SyncIcon />
            Refresh
          </LoadingButton>
        ) : null
      }
    >
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
          <TextField
            label="Search"
            placeholder="Identifier"
            onChange={handleSearchFieldChange}
            value={searchFieldValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              label="status"
              placeholder="Status"
              value={status}
              onChange={handleStatusChange}
              sx={{ width: 150 }}
            >
              <MenuItem value="">All statuses</MenuItem>
              <MenuItem value={ProductStatus.Active}>Active</MenuItem>
              <MenuItem value={ProductStatus.Inactive}>Inactive</MenuItem>
              <MenuItem value={ProductStatus.Removed}>Removed</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select value={type} onChange={handleTypeChange} sx={{ width: 150 }} placeholder="Type" label="type">
              <MenuItem value="">All types</MenuItem>
              <MenuItem value={ProductType.Unknown}>Unknown</MenuItem>
              <MenuItem value={ProductType.Purchase}>Purchase</MenuItem>
              <MenuItem value={ProductType.Subscription}>Subscription</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Set</InputLabel>
            <Select
              label="Set"
              placeholder="Set"
              defaultValue={featureSet}
              onChange={handleSetChange}
              sx={{ width: 150 }}
              disabled={featureSetsisPending}
              endAdornment={
                featureSetsisPending && (
                  <InputAdornment position="end" sx={{ mr: '-5px' }}>
                    <CircularProgress size={20} color="inherit" />
                  </InputAdornment>
                )
              }
            >
              <MenuItem value="">All sets</MenuItem>
              <MenuItem value="-1">Unassigned</MenuItem>
              {featureSets?.map((set) => (
                <MenuItem key={set.id} value={set.id}>
                  {set.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TableListErrorBoundary>
          <Suspense fallback={<ProductsListSkeleton />}>
            <ProductsList
              sorting={sorting}
              pagination={pagination}
              search={search}
              type={type as ProductType}
              status={status as ProductStatus}
              featureSet={featureSet ? Number(featureSet) : undefined}
            />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default ProductsListRoute;
