import { Chip, SxProps, Theme } from '@mui/material';
import { deepPurple, indigo } from '@mui/material/colors';
import { TemplatePaymentType } from 'features/templates';
import { FC } from 'react';

const paymentTypeToSx: Record<TemplatePaymentType, SxProps<Theme>> = {
  [TemplatePaymentType.GoodsSet]: {
    backgroundColor: deepPurple[50],
    color: deepPurple[500],
  },
  [TemplatePaymentType.FeatureSet]: {
    backgroundColor: indigo[50],
    color: indigo[500],
  },
};

const paymentTypeToLabel: Record<TemplatePaymentType, string> = {
  [TemplatePaymentType.GoodsSet]: 'Goods',
  [TemplatePaymentType.FeatureSet]: 'Feature Sets',
};

interface TemplatePaymentTypeChipProps {
  type: TemplatePaymentType;
}

const TemplatePaymentTypeChip: FC<TemplatePaymentTypeChipProps> = ({ type }) => (
  <Chip label={paymentTypeToLabel[type]} sx={paymentTypeToSx[type]} size="small" />
);
export default TemplatePaymentTypeChip;
