import { FC, ReactNode, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { SwipeableDrawer, useTheme } from '@mui/material';

interface DrawerPageProps {
  children: ReactNode;
}

const DrawerPage: FC<DrawerPageProps> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOpen = useCallback(() => {}, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => navigate('..'), theme.transitions.duration.leavingScreen + 1);
  }, [setOpen, navigate, theme.transitions.duration.leavingScreen]);

  return (
    <SwipeableDrawer
      variant="temporary"
      anchor="right"
      sx={{
        flexShrink: 0,
        maxHeight: '100vh',
        [theme.breakpoints.down('md')]: {
          zIndex: theme.zIndex.appBar - 2,
        },
      }}
      PaperProps={{
        sx: {
          height: 'unset',
          position: 'absolute',
          // top: 65,
          bottom: 0,
          backgroundColor: '#F9FAFC',
          width: '100%',
          maxWidth: '33.33%',
          [theme.breakpoints.down('md')]: {
            top: 65,
            maxWidth: '66.66%',
          },
          [theme.breakpoints.down('sm')]: {
            top: 57,
            maxWidth: 'none',
          },
        },
      }}
      SlideProps={{
        appear: true,
      }}
      componentsProps={{
        backdrop: {
          sx: {
            [theme.breakpoints.down('md')]: {
              top: 65,
            },
            [theme.breakpoints.down('sm')]: {
              top: 57,
            },
          },
        },
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default DrawerPage;
