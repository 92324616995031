import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonBase, Skeleton, Typography } from '@mui/material';
import { TemplateType, useGetTemplate } from 'features/templates';
import { useProject } from 'features/project';

const TemplateTypeToSubRoute: Record<TemplateType, string> = {
  [TemplateType.Parameter]: 'parameters',
  [TemplateType.Screen]: 'screens',
};

interface TemplateHeadLinkProps {
  name?: string;
  templateId: number;
}

export const TemplateHeadLink: FC<TemplateHeadLinkProps> = ({ name, templateId }) => {
  const project = useProject();

  const templateQuery = useGetTemplate({
    id: templateId,
  });

  const template = templateQuery.data!;

  return (
    <ButtonBase
      component={Link}
      to={`/projects/${project.id}/templates/${TemplateTypeToSubRoute[template.type]}/${templateId}`}
    >
      <Typography variant="h5" sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>
        {name ?? template.identifier}
      </Typography>
    </ButtonBase>
  );
};

export const TemplateHeadLinkSkeleton: FC = () => (
  <Typography variant="h5">
    <Skeleton width={150} />
  </Typography>
);
