import { FC, Suspense, lazy } from 'react';
import TemplateFormPageSkeleton from 'features/templates/components/TemplateFormPageSkeleton';

const CreateTemplateFormPage = lazy(() => import('features/templates/components/CreateTemplateFormPage'));

const CreateTemplateRoute: FC = () => (
  <Suspense fallback={<TemplateFormPageSkeleton />}>
    <CreateTemplateFormPage />
  </Suspense>
);

export default CreateTemplateRoute;
