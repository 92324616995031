import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router';
import { Platform } from 'types';
import { useProject } from '../context';

type PlatformGuardProps = {
  platform: Platform;
  redirectPath?: string;
};

const PlatformGuard: FC<PropsWithChildren<PlatformGuardProps>> = ({
  platform,
  redirectPath = 'feature-sets',
  children,
}) => {
  const project = useProject();

  redirectPath = `/projects/${project.id}/${redirectPath}`;

  if (project.platform === platform) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PlatformGuard;
