import usePrevious from 'hooks/usePrevious';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface PopRouteWrapperProps {
  depth?: number;
}

const PopRouteWrapper: FC<PropsWithChildren<PopRouteWrapperProps>> = ({ children, depth = 1 }) => {
  const params = useParams();
  const prevParams = usePrevious(params);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(params).length <= 1 || !prevParams || prevParams.projectId === params.projectId) {
      return;
    }

    navigate(new Array(depth).fill('..').join('/'), { replace: true });
  }, [depth, prevParams, params, navigate]);

  return children;
};

export default PopRouteWrapper;
