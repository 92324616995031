import { MoreVert } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useGetSkeletonArray } from 'utils';

const ExperimentsHistoryPageSkeleton: FC = () => {
  const experiments = useGetSkeletonArray(10);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="overline" fontWeight="bold">
          History
        </Typography>
      </Box>
      <Paper sx={{ mt: 2.5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, display: 'flex' }}>
          <Skeleton width={195} height={56} variant="rounded" />
        </Box>
        <TableContainer sx={{ flexGrow: 1 }}>
          <Table
            stickyHeader
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
            })}
          >
            <TableHead>
              <TableRow>
                <TableCell component="th" sx={{ backgroundColor: 'white', width: '50px' }}>
                  <TableSortLabel>ID</TableSortLabel>
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                  <TableSortLabel>Name</TableSortLabel>
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white', width: '300px' }}>
                  Applied variant
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                  <TableSortLabel>Result</TableSortLabel>
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                  <TableSortLabel>Duration</TableSortLabel>
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                  Start version
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                  End version
                </TableCell>
                <TableCell component="th" sx={{ backgroundColor: 'white', width: '70px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {experiments?.map((experiment) => (
                <TableRow key={experiment.id}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                    <Typography variant="caption">
                      <Skeleton />
                    </Typography>
                  </TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ExperimentsHistoryPageSkeleton;
