import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useGetSkeletonArray } from 'utils';
import VariantLayout from './VariantLayout';

const ReviewPageSkeleton: FC = () => {
  const items = useGetSkeletonArray(5);

  return (
    <VariantLayout header={<Skeleton variant="rounded" width="100%" height={120} />}>
      {items.map((item) => (
        <Box key={item.id} sx={{ mt: 1.5 }}>
          <Skeleton variant="rounded" width="100%" height={50} />
        </Box>
      ))}
    </VariantLayout>
  );
};

export default ReviewPageSkeleton;
