import { LoadingButton } from '@mui/lab';
import { Paper } from '@mui/material';
import { useProject } from 'features/project';
import { FC, Suspense, lazy } from 'react';
import { Sync as SyncIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { usePagination } from 'hooks';
import { useSyncVersions, VersionsListSkeleton } from 'features/versions';
import { PageLayout, TableListErrorBoundary } from 'components';

const VersionsList = lazy(() => import('features/versions/components/VersionsList'));

const VersionsListRoute: FC = () => {
  const project = useProject();
  const pagination = usePagination();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: refresh, isPending } = useSyncVersions({
    projectId: project.id,
    config: {
      onSuccess: () => {
        enqueueSnackbar('Versions synchronization has been scheduled!', { variant: 'info' });
      },
    },
  });

  const isSyncing =
    isPending || (project.versions_sync !== null && ['in_progress', 'scheduled'].includes(project.versions_sync));

  return (
    <PageLayout
      name="Versions"
      headerActions={
        <LoadingButton variant="contained" loading={isSyncing} onClick={() => refresh()}>
          <SyncIcon />
          Refresh
        </LoadingButton>
      }
    >
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableListErrorBoundary>
          <Suspense fallback={<VersionsListSkeleton />}>
            <VersionsList pagination={pagination} />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default VersionsListRoute;
