import { FeatureSet } from '../types';
import { FeatureSetDTO } from './types';

const getFeatureSetFromDTO = (dto: FeatureSetDTO): FeatureSet => {
  const featureSet = {
    ...dto,
    created_at: new Date(dto.created_at),
    updated_at: new Date(dto.updated_at),
  };

  return featureSet;
};

export default getFeatureSetFromDTO;
