import { FC, Suspense, lazy } from 'react';
import TemplateFormPageSkeleton from 'features/templates/components/TemplateFormPageSkeleton';

const EditArchivedTemplateFormPage = lazy(
  () => import('features/templates/components/archived/EditArchivedTemplateFormPage')
);

const EditArchivedTemplateRoute: FC = () => (
  <Suspense fallback={<TemplateFormPageSkeleton />}>
    <EditArchivedTemplateFormPage />
  </Suspense>
);

export default EditArchivedTemplateRoute;
