import { Paper } from '@mui/material';
import { useProject } from 'features/project';
import { FC, Suspense, lazy } from 'react';
import FeaturesListSkeleton from 'features/features/components/FeaturesListSkeleton';
import { PageLayout, TableListErrorBoundary } from 'components';

const FeaturesList = lazy(() => import('features/features/components/FeaturesList'));

const FeaturesListRoute: FC = () => {
  const project = useProject();

  return (
    <PageLayout name="Features">
      <Paper>
        <TableListErrorBoundary>
          <Suspense fallback={<FeaturesListSkeleton />}>
            <FeaturesList projectId={project.id} />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default FeaturesListRoute;
