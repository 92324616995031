import { FC } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  colors,
} from '@mui/material';
import { useGetSkeletonArray } from 'utils';
import ExperimentSectionLayout from './ExperimentSectionLayout';

type SkeletonItemProps = { withHeader?: boolean; tableSize: number; rowNames?: Array<string> };

const SkeletonItem: FC<SkeletonItemProps> = ({
  withHeader = false,
  rowNames = ['Variant', 'Traffic split'],
  tableSize,
}) => {
  const items = useGetSkeletonArray(tableSize);

  return (
    <Box>
      {withHeader && (
        <Box p={3}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Skeleton variant="rounded" width={120} height={25} />
            <Skeleton variant="rounded" width={190} height={48} />
            <Stack gap={1} width={120}>
              <Skeleton variant="rounded" width="100%" height={15} />
              <Skeleton variant="rounded" width="100%" height={15} />
            </Stack>
            <Skeleton variant="rounded" width={100} height={35} sx={{ ml: 'auto', mr: 6 }} />
          </Stack>
        </Box>
      )}

      <TableContainer>
        <Table
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.grey[50] }}>
              <TableCell component="th" width={64} />
              {rowNames.map((name) => (
                <TableCell key={name} component="th">
                  {name}
                </TableCell>
              ))}
              <TableCell component="th" width={150} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((experiment) => (
              <TableRow key={experiment.id} sx={{ height: '72px' }}>
                <TableCell>
                  <Skeleton variant="circular" sx={{ ml: 1 }} height={24} width={24} />
                </TableCell>
                {rowNames.map((name) => (
                  <TableCell key={name}>
                    <Skeleton />
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ActiveExperimentsPageSkeleton: FC = () => (
  <Box sx={{ mt: 3 }}>
    <ExperimentSectionLayout head="Active experiment">
      <SkeletonItem withHeader tableSize={2} />
    </ExperimentSectionLayout>
    <ExperimentSectionLayout sx={{ mt: 4 }} head="Current variant">
      <SkeletonItem tableSize={1} rowNames={['Variant', 'Start version', 'End version']} />
    </ExperimentSectionLayout>
    <ExperimentSectionLayout sx={{ mt: 4 }} head="Upcoming experiment">
      <SkeletonItem withHeader tableSize={5} />
    </ExperimentSectionLayout>
  </Box>
);

export default ActiveExperimentsPageSkeleton;
