import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient, SortingParams } from 'api';
import { useProject } from 'features/project';
import { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import { FeatureSet } from '../types';
import getFeatureSetFromDTO from './getFeatureSetFromDTO';
import queryKeys from './queryKeys';
import { FeatureSetDTO } from './types';

type GetFeatureSetsOptions = {
  projectId: number;
  params: Partial<SortingParams>;
};

export const getFeatureSets = async ({ projectId, params }: GetFeatureSetsOptions): Promise<FeatureSet[]> => {
  const res = await apiClient.get<FeatureSetDTO[]>(`/admin/projects/${projectId}/subscriptions/feature-groups`, {
    params,
  });
  return res.data.map((feature) => getFeatureSetFromDTO(feature));
};

type UseProjectFeatureSetsOptions = {
  config?: QueryConfig<typeof getFeatureSets>;
  params: Partial<SortingParams>;
};

export const useProjectFeatureSets = ({ config, params }: UseProjectFeatureSetsOptions) => {
  const project = useProject();
  const projectId = project.id;

  const { order, field } = params;

  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: () => getFeatureSets({ projectId, params }),
    queryKey: queryKeys(projectId).list({ order, field }),
  });
};

type UseSynchronousProjectFeatureSetsOptions = {
  config?: SynchronousQueryConfig<typeof getFeatureSets>;
  params: Partial<SortingParams>;
};

export const useSynchronousProjectFeatureSets = ({ config, params }: UseSynchronousProjectFeatureSetsOptions) => {
  const project = useProject();
  const projectId = project.id;

  const { order, field } = params;

  return useQuery({
    retry: false,
    ...config,
    queryFn: () => getFeatureSets({ projectId, params }),
    queryKey: queryKeys(projectId).list({ order, field }),
  });
};
