import React, { createContext, useContext } from 'react';

export enum VariantsLoadingOptions {
  SAVED = 'saved',
  SAVING = 'saving',
  ERROR = 'error',
}

export interface VariantsContextType {
  loading: VariantsLoadingOptions;
  setLoading: React.Dispatch<React.SetStateAction<VariantsContextType['loading']>>;
}

const VariantsContext = createContext<VariantsContextType>({} as VariantsContextType);

export const useVariantsContext = () => {
  const variantsContext = useContext(VariantsContext);
  return variantsContext;
};

export default VariantsContext;
