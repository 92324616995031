import { apiClient } from 'api';
import { MutationConfig } from 'lib/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { projectQueryKeys } from 'features/project';

export type SyncProductsOptions = {
  projectId: number;
};

export const syncProducts = async ({ projectId }: SyncProductsOptions) => {
  await apiClient.post(`/admin/projects/${projectId}/products/sync`);
};

type MutationFnType = () => ReturnType<typeof syncProducts>;

export type UseSyncProductsMutation = {
  config?: MutationConfig<MutationFnType>;
  projectId: number;
};

export const useSyncProductsMutation = ({ config, projectId }: UseSyncProductsMutation) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: projectQueryKeys.list() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: () => syncProducts({ projectId }),
  });
};
