/* eslint-disable import/prefer-default-export */
import config from 'config';
import { init, FunctionToString } from '@sentry/react';
import { httpClientIntegration } from '@sentry/integrations';

export enum SentryErrorTypes {
  Runtime = 'Runtime',
}

const isDevMode = process.env.NODE_ENV === 'development';

init({
  ignoreErrors: [
    // Network errors such as going offline or being blocked by a proxy
    /.*Failed to fetch.*/g,
    /.*Network Error.*/g,
    /.*CanceledError*/g,
  ],
  integrations: [httpClientIntegration() as FunctionToString],
  dsn: config.SENTRY_DSN,
  release: config.RELEASE_DATE,
  environment: config.ENVIRONMENT,
  maxBreadcrumbs: 10,
  enabled: !isDevMode,
});
