import {
  AppBar,
  Toolbar,
  Tooltip,
  Box,
  IconButton,
  styled,
  Divider,
  useTheme,
  useMediaQuery,
  ThemeProvider,
  Skeleton,
} from '@mui/material';
import { Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';
import { FC } from 'react';
import { darkSubTheme } from 'theme';

const NavbarRoot = styled(AppBar)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding-left: ${({ theme }) => theme.sizes.sidebarWidth}px;
  }
`;

interface NavbarSkeletonProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const NavbarSkeleton: FC<NavbarSkeletonProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  const defaultTheme = useTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={isMobile ? darkSubTheme : defaultTheme}>
      <NavbarRoot position="fixed" elevation={0}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'block',
              },
              display: 'none',
            })}
          >
            {isDrawerOpen ? (
              <IconButton key="drawer_opener" onClick={closeDrawer}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton key="drawer_opener" onClick={openDrawer}>
                <BurgerIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="rounded" height={36} width={200} />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton>
                <Skeleton variant="circular" height={40} width={40} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        <Divider />
      </NavbarRoot>
    </ThemeProvider>
  );
};

export default NavbarSkeleton;
