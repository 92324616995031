/* eslint-disable import/order */
import React, { ComponentType, FC } from 'react';
import { styled, SxProps } from '@mui/material';
import { Apple, Android, Language } from '@mui/icons-material';
import { Platform } from 'types';
import { ProjectProxies } from '../types';

/** icons */
import running from 'assets/running.svg?react';
import fasting from 'assets/fasting.svg?react';
import fitcoach from 'assets/fitcoach.svg?react';
import menscoach from 'assets/menscoach.svg?react';
import walking from 'assets/walking.svg?react';
import yoga from 'assets/yoga.svg?react';

export const projectProxyIcons: Record<ProjectProxies, FC> = {
  running,
  fitcoach,
  walking,
  menscoach,
  yoga,
  fasting,
};

const Root = styled('div')`
  display: inline-flex;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('md')} {
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.09);
  }
`;

const IconPlace = styled('div')`
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.06);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    background: rgba(255, 255, 255, 0.09);
  }
`;

const Icon = styled('img')`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const PlatformIconPlace = styled('div')`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 5px;
  overflow: hidden;
  width: 20px;
`;

const PlatformIcon = styled('svg')`
  width: 100%;
`;

const PlatformToIcon: Record<Platform, ComponentType> = {
  [Platform.iOS]: Apple,
  [Platform.Web]: Language,
  [Platform.Android]: Android,
};

interface ProjectIconProps {
  proxy?: ProjectProxies | null;
  platform?: Platform;
  sx?: SxProps;
}

const ProjectIcon: FC<ProjectIconProps> = ({ proxy, platform, sx }) => (
  <Root sx={sx}>
    <IconPlace>{proxy && <Icon as={projectProxyIcons[proxy]} />}</IconPlace>
    <PlatformIconPlace>{platform && <PlatformIcon as={PlatformToIcon[platform]} />}</PlatformIconPlace>
  </Root>
);

export default ProjectIcon;
