import { FC } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
  Typography,
  Skeleton,
} from '@mui/material';
import { useGetSkeletonArray } from 'utils';

interface ProductsListSkeletonProps {
  size?: number;
}

const ProductsListSkeleton: FC<ProductsListSkeletonProps> = ({ size = 5 }) => {
  const products = useGetSkeletonArray(size);

  return (
    <TableContainer sx={{ flexGrow: 1 }}>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              <TableSortLabel>Identifier</TableSortLabel>
            </TableCell>
            <TableCell component="th" sx={{ width: '120px', backgroundColor: 'white' }}>
              <TableSortLabel>Status</TableSortLabel>
            </TableCell>
            <TableCell component="th" sx={{ width: '160px', backgroundColor: 'white' }}>
              <TableSortLabel>Type</TableSortLabel>
            </TableCell>
            <TableCell component="th" sx={{ width: '200px', backgroundColor: 'white' }}>
              Set
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Info
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map(({ id }) => (
            <TableRow key={id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Typography variant="inherit">
                  <strong>
                    <Skeleton />
                  </strong>
                </Typography>
                <Typography variant="inherit">
                  <Skeleton />
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsListSkeleton;
