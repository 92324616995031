import { FeatureSet } from 'features/feature-sets';

export enum ProductType {
  Subscription = 'subscription',
  Purchase = 'one_time',
  Unknown = 'unknown',
}
export enum ProductStatus {
  Active = 'active',
  Inactive = 'inactive',
  Removed = 'removed',
}

export interface Product {
  id: number;
  type: ProductType;
  name: string;
  status: ProductStatus;
  description: string;
  verbose_name: string;
  groups: FeatureSet[] | null;
}
