import {
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useGetSkeletonArray } from 'utils';
import { MoreVert } from '@mui/icons-material';

const FeatureSetsListSkeleton: FC = () => {
  const featureSets = useGetSkeletonArray(10);

  return (
    <TableContainer>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell variant="head" component="th" sx={{ width: '50px' }}>
              ID
            </TableCell>
            <TableCell variant="head" component="th" sx={{ width: '300px' }}>
              Name
            </TableCell>
            <TableCell variant="head" component="th">
              Features
            </TableCell>
            <TableCell variant="head" component="th">
              Products
            </TableCell>
            <TableCell variant="head" component="th">
              Updated
            </TableCell>
            <TableCell variant="head" component="th" sx={{ width: '100px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {featureSets.map((featureSet) => (
            <TableRow key={featureSet.id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton />
                </Typography>
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <IconButton>
                  <MoreVert />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeatureSetsListSkeleton;
