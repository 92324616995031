import { FC, ReactNode, useMemo, useState } from 'react';
import VariantsContext, { VariantsLoadingOptions } from './VariantsContext';

const VariantsContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<VariantsLoadingOptions>(VariantsLoadingOptions.SAVED);

  const providerValue = useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading]
  );

  return <VariantsContext.Provider value={providerValue}>{children}</VariantsContext.Provider>;
};

export default VariantsContextProvider;
