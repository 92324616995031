import { TableRow, TableCell, IconButton, Skeleton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { FC } from 'react';

const TemplateItemSkeleton: FC = () => (
  <TableRow>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

export default TemplateItemSkeleton;
