import { Version } from 'features/versions';

export enum TemplatePaymentType {
  FeatureSet = 'feature_set',
  GoodsSet = 'good_set',
}

export interface TemplateHistoryEvent {
  id: number;
  type: 'created' | 'updated' | 'archived';
  created_at: Date;
  updated_at: Date;
  user_id: number;
  user?: {
    id: number;
    username: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    created_at: Date;
    updated_at: Date;
  };
}

export enum TemplateType {
  Screen = 'screen',
  Parameter = 'parameter',
}

export interface Template {
  id: number;
  identifier: string;
  schema?: string;
  type: TemplateType;
  parent: Omit<Template, 'parent' | 'events' | 'start_version'> | null;
  payment_type?: TemplatePaymentType;
  start_version: Version;
  start_version_id: number;
  end_version: Version | null;
  end_version_id: number | null;
  events?: TemplateHistoryEvent[];
  created_at: Date;
  updated_at: Date;
}
