import { FC, Suspense, lazy } from 'react';
import TemplateFormPageSkeleton from 'features/templates/components/TemplateFormPageSkeleton';

const EditTemplateFormPage = lazy(() => import('features/templates/components/EditTemplateFormPage'));

const EditScreenRoute: FC = () => (
  <Suspense fallback={<TemplateFormPageSkeleton />}>
    <EditTemplateFormPage />
  </Suspense>
);

export default EditScreenRoute;
