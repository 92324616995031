import * as yup from 'yup';
import { PaginationParams, SearchParams, SortingParams } from 'api';
import { RequireAtLeastOne } from 'types/utils';
import { Template, TemplatePaymentType, TemplateType } from '../types';

export interface TemplateDTO extends Omit<Template, 'created_at' | 'updated_at'> {
  created_at: string;
  updated_at: string;
}

export type TemplatesQueryParams = Partial<PaginationParams> & Partial<SortingParams> & Partial<SearchParams>;

export type CreateTemplatePayload = {
  identifier: string;
  schema: string;
  type: TemplateType;
  parent?: number;
  start_version: number;
  payment_type?: TemplatePaymentType;
};

export type UpdateTemplatePayload = RequireAtLeastOne<CreateTemplatePayload>;

export type ArchiveTemplatePayload = {
  end_version: number;
};

export const TemplatePayloadValidationSchema = yup.object({
  identifier: yup
    .string()
    .matches(/^[a-z][a-z0-9_]{2,}$/, 'Identifier is not valid')
    .required('Identifier is required'),
  start_version: yup.number().strict().required('Start version is required'),
  schema: yup.string().required('Schema is required'),
  type: yup.mixed<TemplateType>().oneOf(Object.values(TemplateType)),
  parent: yup.number().strict(),
  payment_type: yup.mixed<TemplatePaymentType>().oneOf(Object.values(TemplatePaymentType)).nullable(),
});
