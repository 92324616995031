import { Paper, Button } from '@mui/material';
import { FC, Suspense, lazy } from 'react';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import FeatureSetListSkeleton from 'features/feature-sets/components/FeatureSetListSkeleton';
import { PageLayout, TableListErrorBoundary } from 'components';

const FeatureSetsList = lazy(() => import('features/feature-sets/components/FeatureSetList'));

const FeatureSetListRoute: FC = () => (
  <PageLayout
    outlet
    name="Feature Sets"
    headerActions={
      <Button component={Link} to="add" variant="contained" startIcon={<Add />}>
        Create
      </Button>
    }
  >
    <Paper>
      <TableListErrorBoundary>
        <Suspense fallback={<FeatureSetListSkeleton />}>
          <FeatureSetsList />
        </Suspense>
      </TableListErrorBoundary>
    </Paper>
  </PageLayout>
);

export default FeatureSetListRoute;
