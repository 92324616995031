import ExperimentsHistoryPageSkeleton from 'features/experiments/components/ExperimentsHistoryPageSkeleton';
import { FC, Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

const ExperimentsHistoryPage = lazy(() => import('features/experiments/components/ExperimentsHistoryPage'));

const ExperimentsHistoryRoute: FC = () => (
  <>
    <Suspense fallback={<ExperimentsHistoryPageSkeleton />}>
      <ExperimentsHistoryPage />
    </Suspense>
    <Outlet />
  </>
);

export default ExperimentsHistoryRoute;
