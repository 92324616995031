import { FC, useMemo, useState } from 'react';
import { Button, Dialog, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGetVersions, VersionsAutocomplete, Version, VersionStatus } from 'features/versions';
import { processAxiosErrors } from 'api';
import { ModalPageLayout } from 'components';
import { useArchiveTemplate } from '../hooks';
import { Template } from '../types';

interface TemplateArchiveDialogProps {
  template: Template;
  isOpen: boolean;
  onClose: (action?: 'archived') => void;
}

type ErrorType = { message: string } | null;

const TemplateArchiveDialog: FC<TemplateArchiveDialogProps> = ({ template, isOpen, onClose }) => {
  const templateId = template.id;
  const [axiosValidationError, setError] = useState<ErrorType>(null);

  const { mutate: archive, isPending } = useArchiveTemplate({
    config: {
      onSuccess: () => onClose('archived'),
      onError: (x) => {
        processAxiosErrors(x, (field, message) => {
          setError({ message });
        });
      },
    },
  });

  const { data: response } = useGetVersions({ params: { page: 1, per_page: 100 } });
  const { items: versions } = response!;

  const [version, setVersion] = useState<Version | null>(
    versions.find((ver) => ver.status === VersionStatus.Live) || versions[0]
  );

  const handleChange = (_: any, val: Version | null) => {
    setError(null);
    setVersion(val);
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const archiveTemplate = () => {
    archive({ templateId, data: { end_version: version!.id } });
  };

  const error = useMemo<ErrorType>(() => {
    if (version && version.order <= template.start_version.order) {
      return { message: 'End version should be higher than the start version' };
    }

    if (axiosValidationError) {
      return axiosValidationError;
    }

    return null;
  }, [version, axiosValidationError, template]);

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <ModalPageLayout
        size="medium"
        onClose={handleClose}
        name={`Archive ${template.identifier} ${template.type} template?`}
        description="Moving template to archive will break all connections with other templates or variants"
        footer={
          <>
            <Button variant="outlined" size="large" onClick={handleClose} fullWidth>
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              color="error"
              variant="contained"
              loading={isPending}
              onClick={archiveTemplate}
              disabled={!version || !!error}
            >
              Yes, archive
            </LoadingButton>
          </>
        }
      >
        <FormControl sx={{ width: '100%', display: 'flex' }}>
          <VersionsAutocomplete
            value={version}
            onChange={handleChange}
            InputProps={{
              error: !!error,
              label: 'End version',
              helperText: error ? error.message : '',
            }}
          />
        </FormControl>
      </ModalPageLayout>
    </Dialog>
  );
};

export default TemplateArchiveDialog;
