import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Divider, Stack, SxProps, Typography, Theme, styled } from '@mui/material';
import { Cached, CloudDoneOutlined, WarningAmber } from '@mui/icons-material';
import { VariantsLoadingOptions, useVariantsContext } from '../context';

interface VariantLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  sx?: SxProps<Theme>;
  hideLoading?: boolean;
}

const CachedAnimated = styled(Cached)`
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const IconsByLoadingStatus: FC<{ name: VariantsLoadingOptions }> = ({ name }) => {
  switch (name) {
    case VariantsLoadingOptions.SAVED:
      return (
        <Typography color="success.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <CloudDoneOutlined sx={{ mr: 1 }} fontSize="small" /> Saved
        </Typography>
      );

    case VariantsLoadingOptions.SAVING:
      return (
        <Typography color="primary.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <CachedAnimated sx={{ mr: 1 }} fontSize="small" /> Saving
        </Typography>
      );

    case VariantsLoadingOptions.ERROR:
      return (
        <Typography color="error.main" variant="button" sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningAmber sx={{ mr: 1 }} fontSize="small" /> Variant can’t be updated
        </Typography>
      );

    default: {
      return null;
    }
  }
};

const VariantLayout: FC<VariantLayoutProps> = ({ children, header, hideLoading = false, sx }) => {
  const { loading } = useVariantsContext();

  return (
    <Stack sx={{ flexGrow: 1, my: 3, position: 'relative', ...sx }}>
      {!hideLoading && (
        <Box
          sx={(theme) => ({
            p: 1,
            pr: 3,
            right: 0,
            position: 'absolute',
            top: theme.spacing(-11),
          })}
        >
          <IconsByLoadingStatus name={loading} />
        </Box>
      )}
      {header && (
        <>
          <Box>{header}</Box>
          <Divider sx={{ my: 3 }} />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexGrow: 1,
            width: '50%',
          }}
        >
          <Stack sx={{ mt: -1.5, pr: 3, position: 'relative', width: '100%' }}>
            <Box sx={{ position: 'sticky', top: 80 }}>{children}</Box>
          </Stack>
        </Box>

        <Stack sx={{ width: '50%', position: 'relative' }}>
          <Outlet />
        </Stack>
      </Box>
    </Stack>
  );
};

export default VariantLayout;
