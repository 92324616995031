import { capitalize, first } from 'lodash-es';

export const getUserNameByEmail = (email: string) => {
  try {
    return email.split('@')[0].split('.').map(capitalize).join(' ');
  } catch (e) {
    return email;
  }
};

export const getUserAvatarNameByEmail = (email: string) => {
  try {
    return email.split('@')[0].split('.').map(first).map(capitalize).join('');
  } catch (e) {
    return email;
  }
};
