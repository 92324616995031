import { Typography, Box, BoxProps, Paper, Fade } from '@mui/material';
import React, { FC } from 'react';
import { transparentize } from 'polished';

interface ExperimentSectionLayoutProps {
  head: string;
  children: React.ReactNode;
  rollout?: number | null;
}

const ExperimentSectionLayout: FC<ExperimentSectionLayoutProps & BoxProps> = ({
  head,
  children,
  rollout,
  ...props
}) => (
  <Box {...props}>
    <Box sx={{ display: 'flex', alignItems: 'center', height: '34px' }}>
      <Typography variant="overline" fontWeight="bold">
        {head}
      </Typography>
      {Boolean(rollout) && (
        <Fade in>
          <Box
            sx={(theme) => ({
              backgroundColor: transparentize(1 - 0.12, theme.palette.primary.light),
              color: theme.palette.primary.main,
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: 500,
              py: 1,
              px: 2,
              ml: 1.5,
            })}
          >
            {`${rollout}% of traffic`}
          </Box>
        </Fade>
      )}
    </Box>
    <Paper sx={{ mt: 2 }}>{children}</Paper>
  </Box>
);

export default ExperimentSectionLayout;
