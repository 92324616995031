import { FC, Suspense, lazy } from 'react';
import { LinearProgress } from '@mui/material';

const VariantPage = lazy(() => import('features/variants/components/VariantPage'));

const VariantRoute: FC = () => (
  <Suspense fallback={<LinearProgress sx={{ mx: -4 }} />}>
    <VariantPage />
  </Suspense>
);

export default VariantRoute;
