import 'lib/amplify';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext, AuthStateType, Roles, UserData, TokenPayload } from 'features/auth';
import { fetchAuthSession, signInWithRedirect } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useNavigate } from 'react-router';
import { getUserNameByEmail } from 'utils';

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [state, setState] = useState<AuthStateType>('unauthenticated');
  const [roles, setRoles] = useState<Roles>({
    isAdmin: false,
    isEditor: false,
  });

  const navigate = useNavigate();

  const init = useCallback(async () => {
    try {
      const session = await fetchAuthSession();
      const payload = session.tokens?.idToken?.payload as TokenPayload;

      const { email } = payload;
      const name = getUserNameByEmail(email);
      const groups = payload['cognito:groups'] || [];

      setRoles({
        isAdmin: groups?.includes('admin') ?? false,
        isEditor: groups?.includes('editor') ?? false,
      });

      setUser({ name, email });
      setState('authenticated');
    } catch {
      signInWithRedirect();
    }
  }, []);

  useEffect(() => {
    init();

    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedOut':
        case 'signInWithRedirect_failure':
          setUser(null);
          break;
        default:
          break;
      }
    });

    return unsubscribe;
  }, [navigate, init]);

  const contextValue = useMemo(
    () => ({
      user,
      roles,
      state,
    }),
    [user, state, roles]
  );

  if (state === 'unauthenticated') {
    return null;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
