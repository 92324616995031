import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

interface SkeletonEntity {
  id: string;
}

const useGetSkeletonArray = (size: number = 5): SkeletonEntity[] =>
  useMemo(() => new Array(size).fill(null).map(() => ({ id: uuid() })), [size]);

export default useGetSkeletonArray;
