import { useQueryClient } from '@tanstack/react-query';
import { ProjectContext } from 'features/project/context';
import usePrevious from 'hooks/usePrevious';
import { useSnackbar } from 'notistack';
import getProjectPagePath from 'pages/projects/project/getProjectPathPath';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Path, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { Titled } from 'react-titled';
import { useTypedParams } from 'utils';
import { useGetProject } from '../api/getProject';
import { useGetProjects } from '../api/getProjects';
import { Project } from '../types';

const ProjectContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: projects } = useGetProjects();
  const { projectId } = useTypedParams({ projectId: Number });

  const defaultProjectId = projects[0].id;

  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch({ path: getProjectPagePath(':projectId'), end: false });

  const selectedProject = useMemo(
    () => projects.find(({ id }) => id === (projectId ?? defaultProjectId)),
    [projects, projectId, defaultProjectId]
  );

  useEffect(() => {
    if (!projectId) {
      const newPathnameBase = getProjectPagePath(defaultProjectId);

      if (!match) {
        navigate(newPathnameBase);
        enqueueSnackbar(`You have been redirected to the first project available to you.`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    }
  }, [navigate, enqueueSnackbar, projectId, defaultProjectId, match]);

  const { data } = useGetProject({
    projectId: projectId ?? defaultProjectId,
    config: {
      refetchInterval: (query) => {
        const prj = query.state.data;
        if (
          (prj?.products_sync && prj.products_sync !== 'finished') ||
          (prj?.versions_sync && prj.versions_sync !== 'finished')
        ) {
          return 3000;
        }
        return Infinity;
      },
    },
  });

  const project = data!;
  const prevProject = usePrevious(project);

  useEffect(() => {
    if (!prevProject) return;
    if (prevProject.id !== project.id) return;

    if (project.products_sync === 'finished' && prevProject?.products_sync !== project.products_sync) {
      enqueueSnackbar(`${project.name}: Products has been synced successfully!`, { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: ['products', project.id],
      });
    }
    if (project.versions_sync === 'finished' && prevProject?.versions_sync !== project.versions_sync) {
      enqueueSnackbar(`${project.name}: Versions has been synced successfully!`, { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: ['versions', project.id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const setProject = useCallback(
    (prj: Project) => {
      const newPathnameBase = getProjectPagePath(prj.id);

      if (!match) {
        navigate(newPathnameBase);
        return;
      }

      const newPathname = location.pathname.replace(match.pathnameBase, newPathnameBase);
      // We don't need to preserve "state" represented by search and hash
      const newLocation: Partial<Path> = { pathname: newPathname /* search: location.search , hash: location.hash */ };
      navigate(newLocation);
    },
    [match, location, navigate]
  );

  const contextValue = useMemo(
    () => ({
      project,
      selectedProject,
      setProject,
    }),
    [project, selectedProject, setProject]
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      <Titled title={(title) => `${project.name} | ${title}`}>{children}</Titled>
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
