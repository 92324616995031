import { FC, Suspense, lazy } from 'react';
import { DrawerPage } from 'components';
import TemplatePage from 'features/templates/components/TemplatePage';

const TemplatePageSkeleton = lazy(() => import('features/templates/components/TemplatePageSkeleton'));

const ArchivedTemplateRoute: FC = () => (
  <DrawerPage>
    <Suspense fallback={<TemplatePageSkeleton />}>
      <TemplatePage />
    </Suspense>
  </DrawerPage>
);

export default ArchivedTemplateRoute;
