import { ToggleButtonGroup, ToggleButton, SxProps, Theme } from '@mui/material';
import { useTabMatch } from 'hooks';
import { FC } from 'react';
import { Outlet, Link } from 'react-router-dom';

const toogleButtonSx: SxProps<Theme> = (theme) => ({
  px: 3,
  borderColor: theme.palette.grey[200],

  '&.Mui-selected': {
    border: 'none',
  },
});

const ExperimentsLayout: FC = () => {
  const activeTab = useTabMatch(['active', 'history']);

  return (
    <>
      <ToggleButtonGroup
        exclusive
        size="small"
        value={activeTab}
        color="primary"
        sx={{
          position: 'absolute',
          zIndex: 2,
          top: -6,
          right: 0,
        }}
      >
        <ToggleButton sx={toogleButtonSx} value="active" component={Link} to="active">
          Active
        </ToggleButton>
        <ToggleButton sx={toogleButtonSx} value="history" component={Link} to="history">
          History
        </ToggleButton>
      </ToggleButtonGroup>
      <Outlet />
    </>
  );
};

export default ExperimentsLayout;
