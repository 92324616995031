import { FC, Suspense, lazy } from 'react';
import { DrawerPage } from 'components';
import FeatureSetPageSkeleton from 'features/feature-sets/components/FeatureSetPageSkeleton';
import { useTypedParams } from 'utils';

const FeatureSetPage = lazy(() => import('features/feature-sets/components/FeatureSetPage'));

const FeatureSetRoute: FC = () => {
  const { featureSetId } = useTypedParams({ featureSetId: Number });

  return (
    <DrawerPage>
      <Suspense fallback={<FeatureSetPageSkeleton />}>
        <FeatureSetPage featureSetId={featureSetId} />
      </Suspense>
    </DrawerPage>
  );
};

export default FeatureSetRoute;
