import axios from 'axios';
import { FC, useEffect, PropsWithChildren, useMemo } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { CloudOffOutlined, ReplayOutlined } from '@mui/icons-material';
import { Typography, Button, Stack } from '@mui/material';
import { useProject } from 'features/project';
import usePrevious from 'hooks/usePrevious';

const TableListErrorComponent: FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const project = useProject();
  const prevProject = usePrevious(project);

  useEffect(() => {
    if (prevProject && prevProject !== project) {
      resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const errorMessage = useMemo(() => {
    if (axios.isAxiosError(error)) {
      switch (error.response?.status) {
        case 400:
          return 'The project does not have a client server configured';
        case 403:
          return 'Forbidden';
        case 502:
          return 'External Service Error';

        default:
          return 'Server error';
      }
    }

    return 'Server error';
  }, [error]);

  return (
    <Stack alignItems="center" p={5}>
      <CloudOffOutlined sx={{ fontSize: 60 }} color="error" />
      <Typography variant="subtitle1" mt={1} color={(t) => t.palette.text.secondary}>
        {errorMessage}
      </Typography>
      <Button sx={{ mt: 1 }} onClick={resetErrorBoundary} startIcon={<ReplayOutlined />}>
        Try again
      </Button>
    </Stack>
  );
};

const TableListErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={TableListErrorComponent}>
      {children}
    </ErrorBoundary>
  );
};

export default TableListErrorBoundary;
