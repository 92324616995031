import { Project, ProjectProxies } from '../types';
import { ProjectDTO } from './types';

export const projectProxyLabels: Record<ProjectProxies, string> = {
  running: 'Running',
  fitcoach: 'FitCoach',
  walking: 'Walking',
  menscoach: 'MuscleBooster',
  yoga: 'Yoga-Go',
  fasting: 'FastEasy',
};

const getProjectFromDTO = (dto: ProjectDTO): Project => {
  const versionsSyncAt = dto.last_products_sync_at ? new Date(dto.last_products_sync_at) : null;
  const productsSyncAt = dto.last_products_sync_at ? new Date(dto.last_products_sync_at) : null;
  const updatedAt = new Date(dto.updated_at);

  const project: Project = {
    ...dto,
    group_label: projectProxyLabels[dto.proxy],
    last_versions_sync_at: versionsSyncAt,
    last_products_sync_at: productsSyncAt,
    updated_at: updatedAt,
  };

  return project;
};

export default getProjectFromDTO;
