import { useProject } from 'features/project';
import { useTypedParams } from 'utils';
import { UseGetTemplateQuery, useGetTemplateQuery } from '../api';

export type UseGetTemplate = {
  id?: number;
  config?: UseGetTemplateQuery['config'];
};

export const useGetTemplate = ({ id, config }: UseGetTemplate = {}) => {
  const project = useProject();
  const projectId = project.id;

  const { screenId } = useTypedParams({ screenId: Number });

  return useGetTemplateQuery({
    id: id ?? screenId,
    projectId,
    config,
  });
};
