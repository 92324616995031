import { FC } from 'react';
import { isString } from 'lodash-es';
import { Titled } from 'react-titled';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, Box, IconButton, Typography, Stack, StackProps, BoxProps } from '@mui/material';

type Sizes = 'small' | 'medium' | 'large';

const sizeToWidth: Record<Sizes, React.CSSProperties['width']> = {
  small: '350px',
  medium: '500px',
  large: '750px',
};

interface ModalPageLayoutProps {
  onClose?: () => void;
  size?: Sizes;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string | null;
  name: React.ReactNode;
  description?: React.ReactNode;
}

type HeaderProps = { hasBorder?: boolean };
const Header: FC<BoxProps & HeaderProps> = styled(Box)<HeaderProps>`
  border-bottom: ${({ theme, hasBorder }) => (hasBorder ? `1px solid ${theme.palette.grey[300]}` : '')};
`;

const Footer: FC<StackProps> = styled(Stack)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const ModalPageLayout: FC<ModalPageLayoutProps> = ({
  size = 'small',
  onClose,
  children,
  footer = null,
  name,
  description,
  title,
}) => {
  const pageTitle = (() => {
    if (isString(title)) return title;
    /** Fallback value */
    if (isString(name)) return name;
    return '';
  })();

  return (
    <>
      {title !== null && <Titled title={(rest) => `${pageTitle} - ${rest}`} />}

      <Box width={['auto', sizeToWidth[size]]}>
        <Header py={2} px={3} hasBorder={Boolean(children)}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <Stack alignSelf="stretch" direction="column" justifyContent="center">
              <Typography variant="h6">{name}</Typography>
              {description && (
                <Typography variant="body2" mt={1}>
                  {description}
                </Typography>
              )}
            </Stack>
            {onClose && (
              <IconButton sx={{ ml: 2 }} onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Header>
        {children && <Box p={3}>{children}</Box>}
        {footer && (
          <Footer py={2.5} px={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
              {footer}
            </Stack>
          </Footer>
        )}
      </Box>
    </>
  );
};

export default ModalPageLayout;
