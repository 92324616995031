import { SuspenseDialog } from 'components';
import { FC, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'utils';

const EditFeatureSetDialog = lazy(() => import('features/feature-sets/components/EditFeatureSetDialog'));

const EditFeatureSetRoute: FC = () => {
  const navigate = useNavigate();

  const { featureSetId } = useTypedParams({ featureSetId: Number });

  const handleClose = () => {
    navigate('..');
  };

  return (
    <SuspenseDialog maxWidth="sm" keepMounted={false} onClose={handleClose} open>
      <EditFeatureSetDialog onClose={handleClose} featureSetId={featureSetId} />
    </SuspenseDialog>
  );
};

export default EditFeatureSetRoute;
