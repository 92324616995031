import { FC, Suspense, lazy } from 'react';
import { GrayscaleProgress } from 'components';
import { useTypedParams } from 'utils';

const VariantConfigPage = lazy(() => import('features/configs/components/VariantConfigPage'));

const VariantConfigRoute: FC = () => {
  const { configId } = useTypedParams({ configId: Number });

  return (
    <Suspense fallback={<GrayscaleProgress />}>
      <VariantConfigPage configId={configId} />
    </Suspense>
  );
};

export default VariantConfigRoute;
