import { Platform } from 'types';

export enum ProjectSyncStatus {
  Scheduled = 'scheduled',
  InProgress = 'in_progress',
  Finished = 'finished',
}

export enum ProjectProxies {
  FitCoach = 'fitcoach',
  YogaGo = 'yoga',
  MuscleBooster = 'menscoach',
  Running = 'running',
  Walking = 'walking',
  FastEasy = 'fasting',
}

export interface Project {
  id: number;
  name: string;
  can_read: boolean;
  can_write: boolean;
  versions_sync: ProjectSyncStatus | null;
  products_sync: ProjectSyncStatus | null;
  group_label: string;
  platform: Platform;
  proxy: ProjectProxies;
  sublib_version: string;
  last_versions_sync_at: Date | null;
  last_products_sync_at: Date | null;
  updated_at: Date;
}
