import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'api';
import { QueryConfig } from 'lib/react-query';
import { Platform } from 'types';
import { Project, ProjectProxies } from '../types';
import getProjectFromDTO from './getProjectFromDTO';
import { ProjectDTO } from './types';
import queryKeys from './queryKeys';

const platformOrder = Object.values(Platform);
const proxiesOrder = Object.values(ProjectProxies);

export const getProjects = async (signal?: AbortSignal): Promise<Project[]> => {
  const res = await apiClient.get<ProjectDTO[]>('/admin/projects', { signal });
  return res.data
    .map((p) => getProjectFromDTO(p))
    .sort((a, b) => platformOrder.indexOf(a.platform) - platformOrder.indexOf(b.platform))
    .sort((a, b) => proxiesOrder.indexOf(a.proxy) - proxiesOrder.indexOf(b.proxy));
};

type QueryFnType = typeof getProjects;

type UseGetProjectsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetProjects = ({ config }: UseGetProjectsOptions = {}) =>
  useSuspenseQuery({
    ...config,
    retry: 1,
    queryKey: queryKeys.list(),
    queryFn: ({ signal }) => getProjects(signal),
  });
