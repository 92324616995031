import { Paper, Box, TextField } from '@mui/material';
import { FC, Suspense, lazy } from 'react';
import { usePagination, useSearch, useSorting } from 'hooks';
import { Outlet } from 'react-router-dom';
import TemplateListSkeleton from 'features/templates/components/TemplateListSkeleton';
import { Template } from 'features/templates';

const ScreensList = lazy(() => import('features/templates/components/screens/ScreensList'));

const ScreensListRoute: FC = () => {
  const { search, searchFieldValue, handleSearchFieldChange } = useSearch();
  const sorting = useSorting<Template>('identifier');
  const pagination = usePagination([search, sorting.field, sorting.order]);

  return (
    <>
      <Paper>
        <Box sx={{ p: 2 }}>
          <TextField
            label="Search"
            placeholder="ID, version, etc"
            onChange={handleSearchFieldChange}
            value={searchFieldValue}
          />
        </Box>
        <Suspense fallback={<TemplateListSkeleton />}>
          <ScreensList pagination={pagination} sorting={sorting} search={search} />
        </Suspense>
      </Paper>
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  );
};

export default ScreensListRoute;
