import { Autocomplete, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { useStateSearch } from 'hooks';
import { FC, PropsWithChildren, SyntheticEvent, useEffect } from 'react';
import { useGetVersionsAutocomplete } from '../hooks';
import { Version } from '../types';

interface VersionsAutocompleteProps extends PropsWithChildren {
  value: Version | null;
  onChange: (event: SyntheticEvent, val: Version | null) => void;
  InputProps?: TextFieldProps;
  sx?: SxProps<Theme>;
}

const VersionsAutocomplete: FC<VersionsAutocompleteProps> = ({ value, onChange, InputProps, ...props }) => {
  const { search, searchFieldValue, handleSearchFieldChange } = useStateSearch();

  const {
    data,
    refetch,
    isPending: versionsIsPending,
  } = useGetVersionsAutocomplete({
    params: { search, page: 1, per_page: 100 },
  });

  const versions = data!;

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Autocomplete
      {...props}
      value={value}
      options={versions.items || []}
      getOptionLabel={(option: Version) => option.name}
      onChange={onChange}
      loading={versionsIsPending}
      filterOptions={(x) => x}
      renderInput={(params: any) => (
        <TextField {...params} {...InputProps} onChange={handleSearchFieldChange} value={searchFieldValue} />
      )}
    />
  );
};

export default VersionsAutocomplete;
