import { rgba } from 'polished';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Collapse, ListItemIcon, ListItemText, IconButton, ListItem, Button, SxProps, Theme } from '@mui/material';

interface NavItemProps {
  to: string;
  icon?: ReactNode;
  title: ReactNode;
  children?: ReactNode;
  expanded?: boolean;
  sx?: SxProps<Theme>;
}

const NavItem: FC<NavItemProps> = ({ to, icon, title, children, expanded = false, sx, ...props }) => {
  const [open, setOpen] = useState(expanded);
  const handleExpand = useCallback(() => {
    setOpen((s) => !s);
  }, [setOpen]);

  const expandable = Boolean(React.Children.toArray(children).length);

  const path = useResolvedPath(to);
  const match = useMatch({ path: path.pathname, end: expandable, caseSensitive: true });
  const isActive = match != null;

  return (
    <>
      <ListItem sx={{ py: 0.8, ...sx }} {...props}>
        <Button
          to={to}
          variant="text"
          component={Link}
          color="primary"
          disableElevation
          disableRipple={isActive}
          sx={(theme) => ({
            flexGrow: 1,
            display: 'flex',
            textTransform: 'none',
            color: theme.palette.text.primary,
            ...(isActive ? { background: rgba(theme.palette.primary.main, theme.palette.action.hoverOpacity) } : {}),
          })}
        >
          <ListItemIcon sx={{ minWidth: 32, opacity: 0.56 }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </Button>

        {expandable && (
          <IconButton sx={{ ml: 1 }} onClick={handleExpand}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </ListItem>
      {expandable && (
        <Collapse in={open} timeout="auto">
          {children}
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
