import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'api';
import { MutationConfig } from 'lib/react-query';

interface SyncVersionsOptions {
  projectId: number;
}

export const syncVersions = ({ projectId }: SyncVersionsOptions) =>
  apiClient.post(`/admin/projects/${projectId}/versions/sync`);

type MutationFnType = () => ReturnType<typeof syncVersions>;

type UseSyncVersionsOptions = {
  projectId: number;
  config?: MutationConfig<MutationFnType>;
};

export const useSyncVersions = ({ config, projectId }: UseSyncVersionsOptions) =>
  useMutation({
    ...config,
    mutationFn: () => syncVersions({ projectId }),
  });
