import { FC } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import EmptyStateSvg from '../assets/empty-state.svg?react';

const StyledSvgImage = styled(EmptyStateSvg)`
  height: 90px;
  margin: 0 auto;
  display: block;
`;

interface EmptyStateComponentProps extends Omit<BoxProps, 'title'> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}

const EmptyStateComponent: FC<EmptyStateComponentProps> = ({ title, subtitle, ...props }) => (
  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} {...props}>
    <Box sx={{ maxWidth: 400 }}>
      <StyledSvgImage />
      <Typography variant="h6" sx={{ mt: 3, textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, opacity: 0.6, textAlign: 'center' }}>
        {subtitle}
      </Typography>
    </Box>
  </Box>
);

export default EmptyStateComponent;
