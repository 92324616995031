import { SortingParams } from 'api';
import { isNil, pickBy } from 'lodash-es';
import { QueryKey } from '@tanstack/react-query';

export default (projectId: number) => ({
  detail: (featureSetId: number) => ['featureSets', projectId, 'detail', featureSetId] as QueryKey,
  list: (meta: Partial<SortingParams> = {}) =>
    (meta
      ? ['featureSets', projectId, 'list', pickBy(meta, (v) => !isNil(v))]
      : ['featureSets', projectId, 'list']) as QueryKey,
});
