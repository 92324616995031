import { FC, Suspense, lazy } from 'react';
import { ReviewPageSkeleton } from 'features/variants';

const ReviewPage = lazy(() => import('features/variants/components/ReviewPage'));

const ReviewRoute: FC = () => (
  <Suspense fallback={<ReviewPageSkeleton />}>
    <ReviewPage />
  </Suspense>
);

export default ReviewRoute;
