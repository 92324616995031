import config from 'config';
import Axios, { isAxiosError } from 'axios';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { appQueryKeys, queryClient } from 'lib/react-query';

const getAuthorizationHeader = async () => {
  try {
    const session = await fetchAuthSession();
    const jwt = session.tokens?.idToken?.toString();

    return `Bearer ${jwt}`;
  } catch {
    // Any error provokes a 401 status and signing out
    return '';
  }
};

const apiClient = Axios.create({ baseURL: config.ADMIN_API_URL, headers: { 'x-API-key': config.ADMIN_API_KEY } });

apiClient.interceptors.request.use(async (cfg) => {
  cfg.headers.set('Authorization', await getAuthorizationHeader());
  return cfg;
});

apiClient.interceptors.response.use(
  (response) => {
    // getting the current version of API
    queryClient.setQueryData(appQueryKeys.apiVersion, response.headers.version);

    return response;
  },
  async (error) => {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const method = error.response?.config.method;

      if (status === 401) {
        await signOut();
      }

      if (status === 403) {
        // Set error message while ONLY updating the project, w/o reading
        if (method !== 'get') {
          queryClient.setQueryData(appQueryKeys.globalError, {
            value: 'Forbidden for current project',
            updater: Date.now(),
          });
        }
      }
    }

    throw error;
  }
);

export default apiClient;
