import { createContext, useContext } from 'react';
import { Project } from '../types';

export interface ProjectContextType {
  project: Project;
  selectedProject: Project | undefined;
  setProject(project: Project): void;
}

const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);

export const useProject = () => {
  const { project } = useContext(ProjectContext);
  return project;
};

export { ProjectContext };
