import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Accordion,
  AccordionSummary,
  Chip,
  Skeleton,
  Stack,
} from '@mui/material';
import { SidePageLayout } from 'components';
import { FC } from 'react';

const TemplatePageSkeleton: FC = () => (
  <SidePageLayout
    fullHeight
    title={null}
    name={<Skeleton width={100} />}
    headerSidebar={
      <Stack ml="auto">
        <Typography variant="caption" component="div">
          <Skeleton width={150} />
        </Typography>
        <Typography variant="caption" component="div">
          <Skeleton width={150} />
        </Typography>
      </Stack>
    }
  >
    <Box>
      <Typography variant="overline" sx={{ px: 2 }}>
        <Skeleton />
      </Typography>
      <Paper sx={{ px: 2, mt: 1 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell>
                <Chip size="small" label={<Skeleton />} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Typography variant="overline" sx={{ px: 2 }}>
        <Skeleton />
      </Typography>
      <Paper sx={{ px: 2, mt: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell variant="head">
                <Skeleton />
              </TableCell>
              <TableCell variant="head">
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Accordion disabled disableGutters elevation={0} sx={{ p: 0, m: 0, background: 'transparent' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }}>
          <Typography variant="overline" sx={{ px: 2 }}>
            <Skeleton />
          </Typography>
        </AccordionSummary>
      </Accordion>
    </Box>
  </SidePageLayout>
);

export default TemplatePageSkeleton;
