export enum VersionStatus {
  Upcoming = 'upcoming',
  Live = 'live',
  Previous = 'previous',
}

export interface Version {
  id: number;
  name: string;
  status?: VersionStatus;
  project_id: number;
  semver: string;
  order: number;
}
