import { FieldValues } from 'react-hook-form';
import { ApiValidationError } from 'api/types';
import { isAxiosError } from 'axios';
import { objectTypedKeys } from 'utils';

const processAxiosErrors = <TFieldValues extends FieldValues>(
  x: unknown,
  cb: (fieldName: keyof TFieldValues, message: string) => void
) => {
  if (isAxiosError(x)) {
    const errorsData = x.response?.data as ApiValidationError<TFieldValues>;
    if (errorsData?.errors) {
      objectTypedKeys(errorsData.errors).forEach((name) => {
        if (name === 'non_field_errors') {
          cb('root', errorsData.errors[name]!.join(', '));
        } else {
          cb(name, errorsData.errors[name]!.join(', '));
        }
      });
    } else {
      cb('root', 'Server error');
    }
  } else {
    throw x;
  }
};

export default processAxiosErrors;
