import { apiClient } from 'api';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { MutationConfig } from 'lib/react-query';
import { ArchiveTemplatePayload } from './types';
import queryKeys from './queryKeys';

export type ArchiveTemplateOptions = {
  projectId: number;
  templateId: number;
  data: ArchiveTemplatePayload;
};

export const archiveTemplate = async ({ projectId, templateId, data }: ArchiveTemplateOptions): Promise<void> => {
  await apiClient.delete(`/admin/projects/${projectId}/templates/${templateId}`, { data });
};

type MutationFnArgs = { data: ArchiveTemplatePayload; templateId: number };
type MutationFnType = (arg: MutationFnArgs) => ReturnType<typeof archiveTemplate>;

export interface UseArchiveTemplateMutation {
  projectId: number;
  config?: MutationConfig<MutationFnType>;
}

export const useArchiveTemplateMutation = ({ projectId, config }: UseArchiveTemplateMutation) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      const [, { templateId }] = args;

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKeys(projectId).allLists() }),
        queryClient.invalidateQueries({ queryKey: queryKeys(projectId).detail(templateId) }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ data, templateId }: MutationFnArgs) => archiveTemplate({ projectId, templateId, data }),
  });

  return mutation;
};
