import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type UseQueryResult = readonly [string, (next: string | undefined) => void];

export default function useQueryState(key: string, initialState?: string): UseQueryResult {
  const [, setSearchParams] = useSearchParams();

  /*
    TODO: find ReactRouter based solution with useSearchParams and PR.
    Problem: having concurrent updates of search params won't affect due to useLocation inside the useSearchParams
    */

  const setState = useCallback(
    (nextValue: string | undefined) => {
      const params = new URLSearchParams(window.location.search);
      if (!nextValue) {
        params.delete(key);
      } else {
        params.set(key, nextValue);
      }

      setSearchParams(params);
    },
    [key, setSearchParams]
  );

  const state = new URLSearchParams(window.location.search).get(key) || initialState || '';

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (initialState && !params.has(key)) {
      params.set(key, initialState);
      setSearchParams(params, { replace: true });
    }
  }, [key, initialState, setSearchParams]);

  return [state, setState];
}
