import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import GlobalErrorBoundaryFallback from 'components/GlobalErrorBoundaryFallback';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const GlobalErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={GlobalErrorBoundaryFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
