import { FC } from 'react';
import { SidePageLayout } from 'components';
import { Box, Typography, Table, TableBody, TableCell, TableRow, Paper, Skeleton, Stack } from '@mui/material';
import { useGetSkeletonArray } from 'utils';

const FeatureSetPageSkeleton: FC = () => {
  const features = useGetSkeletonArray(3);
  const products = useGetSkeletonArray(5);

  return (
    <SidePageLayout
      title={null}
      name={<Skeleton width={125} />}
      headerSidebar={
        <Stack ml="auto">
          <Typography variant="caption" component="div">
            <Skeleton width={115} />
          </Typography>
          <Typography variant="caption" component="div">
            <Skeleton width={115} />
          </Typography>
        </Stack>
      }
    >
      <Box>
        <Typography variant="overline" sx={{ px: 2 }}>
          Features
        </Typography>
        <Paper sx={{ px: 2, mt: 1 }}>
          <Table size="small">
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.id}>
                  <TableCell>
                    <Skeleton width={90} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="overline" sx={{ px: 2 }}>
          Products
        </Typography>
        <Paper sx={{ px: 2, mt: 1 }}>
          <Table size="small">
            <TableBody>
              {products?.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Skeleton width={100} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </SidePageLayout>
  );
};

export default FeatureSetPageSkeleton;
