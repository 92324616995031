import { FC } from 'react';
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useGetSkeletonArray } from 'utils';

const UsersListSkeleton: FC = () => {
  const users = useGetSkeletonArray(10);

  return (
    <TableContainer>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white', width: 70 }}>
              ID
            </TableCell>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
              User
            </TableCell>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
              Access
            </TableCell>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
              Created at
            </TableCell>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white' }}>
              Updated at
            </TableCell>
            <TableCell variant="head" component="th" sx={{ backgroundColor: 'white', width: 200 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell align="right">
                <Box height={30} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersListSkeleton;
