import { FC, Suspense, lazy } from 'react';
import { DrawerPage } from 'components';
import ExperimentPageSkeleton from 'features/experiments/components/ExperimentPageSkeleton';

const ExperimentPage = lazy(() => import('features/experiments/components/ExperimentPage'));

const ExperimentRoute: FC = () => (
  <DrawerPage>
    <Suspense fallback={<ExperimentPageSkeleton />}>
      <ExperimentPage />
    </Suspense>
  </DrawerPage>
);

export default ExperimentRoute;
