import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { APIListResponse, ListResponse, apiClient, getAPIListResponse } from 'api';
import { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import { Version } from '../types';
import queryKeys from './queryKeys';
import { VersionsQueryParams } from './types';

interface GetVersionsOptions {
  projectId: number;
  params: VersionsQueryParams;
}

export const getVersions = async (
  { projectId, params }: GetVersionsOptions,
  signal?: AbortSignal
): Promise<ListResponse<Version>> => {
  const res = await apiClient.get<APIListResponse<Version>>(`/admin/projects/${projectId}/versions`, {
    params,
    signal,
  });

  return getAPIListResponse(res.data);
};

export type UseGetVersionsQuery = {
  projectId: number;
  params: VersionsQueryParams;
  config?: QueryConfig<typeof getVersions>;
};

export const useGetVersionsQuery = ({ config, projectId, params }: UseGetVersionsQuery) =>
  useSuspenseQuery({
    ...config,
    queryKey: queryKeys(projectId).list(params),
    queryFn: ({ signal }) => getVersions({ projectId, params }, signal),
  });

export type UseGetSynchronousVersionsQuery = {
  projectId: number;
  params: VersionsQueryParams;
  config?: SynchronousQueryConfig<typeof getVersions>;
};

export const useGetSynchronousVersionsQuery = ({ config, projectId, params }: UseGetSynchronousVersionsQuery) =>
  useQuery({
    ...config,
    queryKey: queryKeys(projectId).list(params),
    queryFn: ({ signal }) => getVersions({ projectId, params }, signal),
  });
