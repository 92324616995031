import * as yup from 'yup';
import { Feature } from 'features/features';
import { AndroidProduct, AppleProduct, WebProduct } from '../types';

export interface FeatureSetDTO {
  id: number;
  name: string;
  features: Feature[];
  android_products_count?: number;
  apple_products_count?: number;
  web_products_count?: number;
  web_products?: WebProduct[];
  apple_products?: AppleProduct[];
  android_products?: AndroidProduct[];
  created_at: string;
  updated_at: string;
}

export interface CreateFeatureSetPayload {
  name: string;
  features: number[];
}

export interface UpdateFeatureSetPayload {
  name?: string;
  features?: number[];
}

export const featureSetCreateValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  features: yup.array().of(yup.number().required()).min(1).required(),
});

export const featureSetEditValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  features: yup.array().of(yup.number().required()).required(),
});
