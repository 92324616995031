import { FC } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton } from '@mui/material';
import { useGetSkeletonArray } from 'utils';

interface VersionsListSkeletonProps {
  size?: number;
}

const VersionsListSkeleton: FC<VersionsListSkeletonProps> = ({ size }) => {
  const versions = useGetSkeletonArray(size);

  return (
    <TableContainer sx={{ flexGrow: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Name
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map(({ id }) => (
            <TableRow key={id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VersionsListSkeleton;
