import { FC } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { ExtractJSONParamsTypeOptions } from 'utils/extractJSONParams';
import { Tooltip } from '@mui/material';

type Modification = NonNullable<ExtractJSONParamsTypeOptions['modification']>;

interface ModificationInfoProps {
  modification: Modification;
}

const modificationTexts: Record<Modification, string> = {
  allOf: '(AND) Must be valid against all of the subschemas',
  anyOf: '(OR) Must be valid against any of the subschemas',
  oneOf: '(XOR) Must be valid against exactly one of the subschemas',
  not: '(NOT) Must not be valid against the given schema',
};

const ModificationInfo: FC<ModificationInfoProps> = ({ modification }) => (
  <Tooltip placement="top" title={modificationTexts[modification]}>
    <InfoOutlined sx={{ fontSize: 16, verticalAlign: 'middle', ml: 1 }} color="disabled" />
  </Tooltip>
);

export default ModificationInfo;
