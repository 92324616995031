import { useProject } from 'features/project';
import { UseGetFlowsQuery, useGetFlowsQuery } from '../api';

export type UseGetFlows = {
  config?: UseGetFlowsQuery['config'];
};

export const useGetFlows = ({ config }: UseGetFlows = {}) => {
  const project = useProject();
  const projectId = project.id;

  return useGetFlowsQuery({
    config,
    projectId,
  });
};
