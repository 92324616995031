import { styled, Box, IconButton, css, lighten } from '@mui/material';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const IconButtonBoxStyled = styled(Box)`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  align-items: center;
  z-index: 2;

  &:hover > button {
    opacity: 1;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  opacity: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  transition: ${({ theme }) => theme.transitions.create('opacity')};

  &:hover {
    background: ${({ theme }) => lighten(theme.palette.primary.light, 0.9)};
  }
`;

type LinkStyledProps = {
  highlighted: boolean;
  enableHover: boolean;
  alwaysShowDeleteIcon: boolean;
};

export const LinkStyled: FC<LinkProps & LinkStyledProps> = styled(Link)<LinkStyledProps>`
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  text-decoration: none;
  color: inherit;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background: ${({ theme, highlighted }) =>
    highlighted ? lighten(theme.palette.primary.light, 0.7) : theme.palette.background.paper};

  .delete-icon {
    display: none;
  }

  .attach-icon {
    display: block;
  }

  ${({ enableHover }) =>
    enableHover &&
    css`
      &:hover {
        .delete-icon {
          display: block;
        }

        .attach-icon {
          display: none;
        }
      }
    `}

  ${({ alwaysShowDeleteIcon }) =>
    alwaysShowDeleteIcon &&
    css`
      .delete-icon {
        display: block;
      }

      .attach-icon {
        display: none;
      }
    `}
`;
