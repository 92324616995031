import { Paper, Box, TextField, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { FC, Suspense, lazy } from 'react';
import { usePagination, useQueryState, useSearch, useSorting } from 'hooks';
import { Template, TemplateType } from 'features/templates';
import { Outlet } from 'react-router-dom';
import ArchivedTemplatesListSkeleton from 'features/templates/components/archived/ArchivedTemplatesListSkeleton';

const ArchivedTemplatesList = lazy(() => import('features/templates/components/archived/ArchivedTemplatesList'));

const ArchivedTemplatesListRoute: FC = () => {
  const { search, searchFieldValue, handleSearchFieldChange, resetSearch } = useSearch();
  const sorting = useSorting<Template>('identifier');
  const [type, setType] = useQueryState('type');
  const pagination = usePagination([search, sorting.field, sorting.order, type]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType((event.target.value as TemplateType) || '');
    resetSearch();
  };

  return (
    <>
      <Paper>
        <Box sx={{ p: 2, display: 'flex' }}>
          <TextField
            label="Search"
            placeholder="ID, version, etc"
            onChange={handleSearchFieldChange}
            value={searchFieldValue}
          />
          <FormControl sx={{ ml: 2, minWidth: '100px' }}>
            <InputLabel>Type</InputLabel>
            <Select value={type} label="Type" onChange={handleTypeChange}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value={TemplateType.Screen}>Screen</MenuItem>
              <MenuItem value={TemplateType.Parameter}>Parameter</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Suspense fallback={<ArchivedTemplatesListSkeleton />}>
          <ArchivedTemplatesList pagination={pagination} sorting={sorting} search={search} type={type} />
        </Suspense>
      </Paper>
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  );
};

export default ArchivedTemplatesListRoute;
