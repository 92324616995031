import { apiClient } from 'api';
import { QueryConfig } from 'lib/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { Template } from '../types';
import { TemplateDTO } from './types';
import getTemplateFromDTO from './getTemplateFromDTO';

export type GetTemplateOptions = {
  projectId: number;
  id: number;
};

export const getTemplate = async ({ projectId, id }: GetTemplateOptions, signal?: AbortSignal): Promise<Template> => {
  const res = await apiClient.get<TemplateDTO>(`/admin/projects/${projectId}/templates/${id}`, { signal });
  return getTemplateFromDTO(res.data);
};

export type UseGetTemplateQuery = {
  config?: QueryConfig<typeof getTemplate>;
  projectId: number;
  id: number;
};

export const useGetTemplateQuery = ({ config, projectId, id }: UseGetTemplateQuery) =>
  useSuspenseQuery({
    staleTime: 60 * 1000,
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplate({ projectId, id }, signal),
    queryKey: queryKeys(projectId).detail(id),
  });
