import { useProject } from 'features/project';
import { UseSyncProductsMutation, useSyncProductsMutation } from '../api';

export type UseSyncProducts = Pick<UseSyncProductsMutation, 'config'>;

export const useSyncProducts = ({ config }: UseSyncProducts = {}) => {
  const project = useProject();
  const projectId = project.id;

  return useSyncProductsMutation({
    config,
    projectId,
  });
};
