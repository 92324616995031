import { Box, CircularProgress, Dialog, DialogProps, Fade, Typography } from '@mui/material';
import { FC, PropsWithChildren, Suspense, useLayoutEffect, useState } from 'react';

/** Used to find out when Suspense has been resolved */
const Loader: FC<PropsWithChildren<{ onComplete: () => void; omUnmount?: () => void }>> = ({
  onComplete,
  omUnmount,
  children,
}) => {
  useLayoutEffect(() => {
    if (onComplete) {
      onComplete();
    }

    return () => omUnmount?.();
  }, [onComplete, omUnmount]);

  return children;
};

const EmptyPaper: FC<PropsWithChildren> = ({ children }) => children;

const SuspenseDialog: FC<DialogProps> = ({ children, ...dialogProps }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Dialog {...dialogProps} PaperComponent={loaded ? undefined : EmptyPaper}>
      <Suspense
        fallback={
          <Typography color="background.paper">
            <CircularProgress size={36} color="inherit" />
          </Typography>
        }
      >
        <Loader onComplete={() => setLoaded(true)} omUnmount={() => setLoaded(false)}>
          <Fade in>
            <Box>{children}</Box>
          </Fade>
        </Loader>
      </Suspense>
    </Dialog>
  );
};

export default SuspenseDialog;
