import { useProject } from 'features/project';
import { UseGetSynchronousVersionsQuery, useGetSynchronousVersionsQuery } from '../api';

export type UseGetVersionsAutocomplete = Pick<UseGetSynchronousVersionsQuery, 'config' | 'params'>;

export const useGetVersionsAutocomplete = ({ config, params }: UseGetVersionsAutocomplete) => {
  const project = useProject();
  const projectId = project.id;

  return useGetSynchronousVersionsQuery({
    params,
    config,
    projectId,
  });
};
