import {
  QueryClient,
  QueryClientConfig,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const appQueryKeys = {
  apiVersion: ['app/apiVersion'] as QueryKey,
  globalError: ['app/globalError'] as QueryKey,
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);

type Fn = (...args: any[]) => any;

export type ExtractFnReturnType<FnType extends Fn> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends Fn> = Omit<
  UseSuspenseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

export type SynchronousQueryConfig<QueryFnType extends Fn> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

type GetTVariables<T> = T extends (...args: infer R) => any ? (R extends [infer TVar, ...any] ? TVar : void) : never;

export type MutationConfig<MutationFnType extends Fn> = UseMutationOptions<
  ExtractFnReturnType<Awaited<ReturnType<MutationFnType>>>,
  AxiosError,
  GetTVariables<MutationFnType>
>;
