import { Template } from '../types';
import { TemplateDTO } from './types';

const getTemplateFromDTO = (dto: TemplateDTO): Template => {
  const createdAt = new Date(dto.created_at);
  const updatedAt = new Date(dto.updated_at);

  const template = {
    ...dto,
    created_at: createdAt,
    updated_at: updatedAt,
  };

  return template;
};

export default getTemplateFromDTO;
