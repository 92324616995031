import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { appQueryKeys } from 'lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'hooks';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const Base = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const Main = styled('main')`
  flex-grow: 1;
  padding: 0 ${({ theme: t }) => t.spacing(4)} ${({ theme: t }) => t.spacing(4)};
  display: flex;
  flex-direction: column;

  ::before {
    content: '';
    display: block;
    ${({ theme: t }) => t.mixins.toolbar}
  }
`;

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen: isDrawerOpen, open: openDrawer, close: closeDrawer } = useModal();

  const { data: globalError } = useQuery<{ value: string }, Error>({ queryKey: appQueryKeys.globalError });

  useEffect(() => {
    if (globalError?.value) {
      enqueueSnackbar(globalError.value, { variant: 'error' });
    }
  }, [globalError, enqueueSnackbar]);

  useEffect(() => {
    closeDrawer();
  }, [closeDrawer, location.pathname]);

  return (
    <Base>
      <Navbar isDrawerOpen={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
      <Sidebar isShown={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
      <Main>{children}</Main>
    </Base>
  );
};

export default AppLayout;
