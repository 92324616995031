import { FC, Suspense, lazy } from 'react';
import { DrawerPage } from 'components';
import TemplatePageSkeleton from 'features/templates/components/TemplatePageSkeleton';

const TemplatePage = lazy(() => import('features/templates/components/TemplatePage'));

const ScreenRoute: FC = () => (
  <DrawerPage>
    <Suspense fallback={<TemplatePageSkeleton />}>
      <TemplatePage />
    </Suspense>
  </DrawerPage>
);

export default ScreenRoute;
