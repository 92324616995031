import { FC, Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { ActiveExperimentsPageSkeleton } from 'features/experiments';

const ActiveExperimentsPage = lazy(() => import('features/experiments/components/ActiveExperimentsPage'));

const ActiveExperimentsRoute: FC = () => (
  <>
    <Suspense fallback={<ActiveExperimentsPageSkeleton />}>
      <ActiveExperimentsPage />
    </Suspense>
    <Outlet />
  </>
);

export default ActiveExperimentsRoute;
