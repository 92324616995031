import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Divider } from '@mui/material';
import { PageLayout } from 'components';
import useTabMatch from 'hooks/useTabMatch';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const TemplatesParentRoute: FC = () => {
  const activeTab = useTabMatch(['screens', 'parameters', 'archive']);

  return (
    <PageLayout
      outlet
      title={null}
      name="Templates"
      headerActions={
        <Button variant="contained" component={Link} to="add" startIcon={<AddIcon />}>
          Create
        </Button>
      }
    >
      <Box sx={{ mb: 2 }}>
        <Tabs value={activeTab}>
          <Tab label="Screens" value="screens" component={Link} to="screens" />
          <Tab label="Parameters" value="parameters" component={Link} to="parameters" />
          <Tab label="Archive" value="archive" component={Link} to="archive" />
        </Tabs>
        <Divider sx={{ mx: -4, mt: '-1px' }} />
      </Box>
    </PageLayout>
  );
};

export default TemplatesParentRoute;
