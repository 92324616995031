import { isBoolean } from 'lodash-es';
import { StrictRJSFSchema } from '@rjsf/utils';

type JSONSchemaTypeNames = StrictRJSFSchema['type'];

export type ExtractJSONParamsTypeOptions = {
  prefix?: string;
  modification?: keyof Pick<StrictRJSFSchema, 'allOf' | 'anyOf' | 'oneOf' | 'not'>;
};

type TypeListItem = [string, JSONSchemaTypeNames, ExtractJSONParamsTypeOptions?];
const primitiveTypes: JSONSchemaTypeNames = ['string', 'number', 'integer', 'boolean'];

const parseSchema = (
  schema: StrictRJSFSchema,
  parentType: JSONSchemaTypeNames = 'object',
  parentKey: string = 'root'
): Array<TypeListItem> => {
  const results: Array<TypeListItem> = [];

  if (
    /** Primitive types */
    schema.type &&
    [schema.type].flat().some((type) => primitiveTypes.includes(type))
  ) {
    /** Don't show nested primitives */
    if (parentType === 'array') return results;

    results.push([parentKey, schema.type]);
    return results;
  }

  if (schema.type === 'array') {
    results.push([parentKey, schema.type]);

    if (schema.items) {
      const items = [schema.items].flat();

      items.forEach((sch, arrayIndex) => {
        if (!isBoolean(sch) && sch.type) {
          parseSchema(sch, 'array', `${parentKey}.[${arrayIndex}]`).forEach((arrayResult) => {
            results.push(arrayResult);
          });
        }
      });
    }
  }

  if (schema.type === 'object' || schema.properties) {
    Object.entries(schema.properties || {}).forEach(([key, sch]) => {
      if (isBoolean(sch)) return;

      parseSchema(sch, 'object', key).forEach(([k, v, opts]) => {
        const prefix = parentKey === 'root' ? '' : `${parentKey}.`;
        results.push([k, v, { ...opts, prefix: (opts?.prefix ?? '') + prefix }]);
      });
    });

    if (schema.allOf) {
      schema.allOf.forEach((sch) => {
        if (isBoolean(sch)) return;

        parseSchema(sch, 'object', parentKey).forEach(([k, v, opts]) => {
          results.push([k, v, { ...opts, modification: 'allOf' }]);
        });
      });
    }

    if (schema.anyOf) {
      schema.anyOf.forEach((sch) => {
        if (isBoolean(sch)) return;

        parseSchema(sch, 'object', parentKey).forEach(([k, v, opts]) => {
          results.push([k, v, { ...opts, modification: 'anyOf' }]);
        });
      });
    }

    if (schema.oneOf) {
      schema.oneOf.forEach((sch) => {
        if (isBoolean(sch)) return;

        parseSchema(sch, 'object', parentKey).forEach(([k, v, opts]) => {
          results.push([k, v, { ...opts, modification: 'oneOf' }]);
        });
      });
    }
  }

  return results;
};

export default function extractJSONParams(input: string): Array<TypeListItem> {
  const data = JSON.parse(input) as StrictRJSFSchema;
  if (data.type !== 'object' || !data.properties) {
    return [];
  }

  const parsedSchema = parseSchema(data);
  return parsedSchema;
}
