import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface ThemeOptions {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface Palette {
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
  }
}

export const themeOptions: ThemeOptions = {
  sizes: {
    sidebarWidth: 248,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0076e5',
    },
    dark: {
      main: '#111827',
    },
    secondary: {
      main: '#111827',
    },
    background: {
      default: '#f9fafc',
    },
  },
};

const theme = createTheme(themeOptions);

export const darkSubTheme = createTheme({
  ...themeOptions,
  palette: {
    mode: 'dark',
    primary: {
      main: '#0076e5',
    },
    dark: {
      main: '#111827',
    },
    secondary: {
      main: '#111827',
    },
    background: {
      paper: 'rgba(17, 24, 39, 1)',
    },
  },
});

export default theme;
