import { QueryKey } from '@tanstack/react-query';
import { TemplateType } from '../types';
import { TemplatesQueryParams } from './types';

export default (projectId: number) => ({
  allLists: () => ['templates', projectId, 'list'] as QueryKey,
  list: (templateType: TemplateType, meta: TemplatesQueryParams) =>
    ['templates', projectId, 'list', templateType, meta] as QueryKey,
  archived: (templateType: TemplateType, meta: TemplatesQueryParams) =>
    ['templates', projectId, 'archived', templateType, meta] as QueryKey,
  detail: (id: number) => ['templates', projectId, 'detail', id] as QueryKey,
});
