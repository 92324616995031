import { FC, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuspenseDialog } from 'components';

const CreateFeatureSetDialog = lazy(() => import('features/feature-sets/components/CreateFeatureSetDialog'));

const CreateFeatureSetRoute: FC = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('..');
  };

  return (
    <SuspenseDialog maxWidth="sm" onClose={handleClose} open>
      <CreateFeatureSetDialog onClose={handleClose} />
    </SuspenseDialog>
  );
};

export default CreateFeatureSetRoute;
