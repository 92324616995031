import { debounce } from 'lodash-es';
import { useState, ChangeEvent, useCallback, useMemo } from 'react';

interface UseSearchResult {
  search: string | undefined;
  searchFieldValue: string;
  handleSearchFieldChange: (event: ChangeEvent<HTMLInputElement>) => void;
  resetSearch: () => void;
}

export default function useStateSearch(): UseSearchResult {
  const [search, setSearch] = useState<string | undefined>();
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const debouncedSearch = useMemo(() => debounce(setSearch, 500), [setSearch]);

  const handleSearchFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchFieldValue(event.target.value);
      debouncedSearch(event.target.value);
    },
    [debouncedSearch]
  );

  const resetSearch = useCallback(() => {
    setSearchFieldValue('');
    setSearch(undefined);
  }, [setSearch]);

  // TODO: Cover project id change and rerendering with empty search

  return {
    search,
    searchFieldValue,
    handleSearchFieldChange,
    resetSearch,
  };
}
