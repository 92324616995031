import { Box, styled } from '@mui/material';
import { rgba } from 'polished';
import { FC } from 'react';

const VariantBadgeRoot = styled(Box)<{ variant?: 'control' | 'default'; size?: 'large' | 'medium' }>(
  ({ theme, variant = 'default', size = 'medium' }) => `
  border-radius: 100%;
  width: ${size === 'medium' ? theme.spacing(3) : theme.spacing(5)};
  height: ${size === 'medium' ? theme.spacing(3) : theme.spacing(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${variant === 'control' ? theme.palette.warning.main : theme.palette.primary.main};
  background:  ${
    variant === 'control' ? rgba(theme.palette.warning.light, 0.12) : rgba(theme.palette.primary.light, 0.12)
  };
  font-size: ${size === 'medium' ? '12px' : '18px'};
  font-weight: 700;

  & span:last-of-type {
    font-size: ${size === 'medium' ? '10px' : '14px'};
  }
`
);

interface VariantBadgeProps {
  index: number;
  size?: 'large' | 'medium';
}

const VariantBadge: FC<VariantBadgeProps> = ({ index, size = 'medium' }) => (
  <VariantBadgeRoot variant={index === 0 ? 'control' : 'default'} size={size}>
    {index === 0 ? (
      <div>C</div>
    ) : (
      <div>
        V<span>{index}</span>
      </div>
    )}
  </VariantBadgeRoot>
);

export default VariantBadge;
